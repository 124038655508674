import { useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "./store/actions";
import { ButtonRegister, ButtonCancel, InputRegister, RegisterCard, RegisterHeader, RegisterRadioButton, LoginButton, RegisterRaceSelection, RegisterBackground, RegisterGridLeft, RegisterGridRight, SwiperContainer, CharacterImage, NavigationButton } from "../../styles/StyledRegister";
import { toast } from "react-toastify";
import { GameLoading, GameLoadingText, RotatingImage } from "../../styles/StyledGame";
import { Swiper, SwiperSlide } from "swiper/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import "swiper/css";
import { EffectCoverflow, Navigation, Pagination } from "swiper";

const Register = (props) => {
  const dispatch = useDispatch();
  const userExists = props.user.email ? true : false;
  const userFail = props.loginFailed;
  const usernameExist = props.usernameUsed;
  const audioBackmusicRef = useRef();
  const [gender, setGender] = useState("male");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordSec, setPasswordSec] = useState("");

  //swiper variables
  const swiperRef = useRef(null);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const maxGenderImages = gender === "male" ? 29 : 33;
  const imagePaths = Array.from({ length: gender === "male" ? 29 : 33 }, (_, index) => `/assets/${gender}/${gender}Image${index + 1}.png`);
  const goToPrevious = () => {
    playClickSound();
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
      setActiveImageIndex(Number(swiperRef?.current?.swiper?.activeIndex));
    }
  };
  const goToNext = () => {
    playClickSound();
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
      setActiveImageIndex(Number(swiperRef?.current?.swiper?.activeIndex));
    }
  };

  function handleCancel() {
    window.open("/", "_self");
  }

  const playClickSound = async () => {
    const clicksound = new Audio("/assets/audio/buttonClick.mp3");
    clicksound.volume = 0.09;
    clicksound.play();
  };

  //Register Successful
  useEffect(() => {
    if (userExists) {
      const userSaved = {
        username: props.user.username,
        key: props.user.key,
      };
      localStorage.setItem("user", JSON.stringify(userSaved));
      toast(`Register was successful! Welcome ${props.user.username_visible}`);

      setTimeout(() => {
        window.open("/game", "_self");
      }, 2000);
      setEmail("");
      setPassword("");
    }
  }, [userExists]);

  //Error on API
  useEffect(() => {
    if (userFail) {
      localStorage.removeItem("user");
      toast.error(props.errorMessage);
      dispatch(Actions.doResetFail());
      dispatch(Actions.setLoading(false));
    }
  }, [userFail]);

  useEffect(() => {
    const loginBackmusic = new Audio("/assets/audio/loginBackmusic.mp3");
    loginBackmusic.volume = 0.05;
    loginBackmusic.loop = true;
    loginBackmusic.play();
  }, []);

  function containsOnlyLetters(str) {
    return /^[a-zA-Z]+$/.test(str);
  }

  //REGISTER FUNC PART 1
  async function handleRegister() {
    dispatch(Actions.setLoading(true));
    //# PART 1 check if its correct usernmae etc..
    if (username?.length <= 4 || username?.length >= 15) {
      toast.error("Username need to have more than 4 letters and max 15");
      dispatch(Actions.setLoading(false));
    } else if (!containsOnlyLetters(username)) {
      toast.error("Username can have only letters without diacritics");
      dispatch(Actions.setLoading(false));
    } else if (!email?.includes("@") || !email?.includes(".")) {
      toast.error("Email is incorrect");
      dispatch(Actions.setLoading(false));
    } else if (password?.length < 8) {
      toast.error("Password need to have more than 8 letters");
      dispatch(Actions.setLoading(false));
    } else if (password !== passwordSec) {
      toast.error("Passwords must be the same");
      dispatch(Actions.setLoading(false));
    } else {
      dispatch(Actions.checkUsername(username)); //# PART 2 check username
    }
  }

  //Check Username exists PART2
  useEffect(() => {
    console.log("[info] usernameExist: ", usernameExist);
    if (usernameExist === false) {
      console.log("[info] username does not exist");
      dispatch(Actions.doRegister(email, password, username, gender, "warrior", activeImageIndex));
    } else if (usernameExist === true) {
      toast.error("Username is already used");
      dispatch(Actions.doResetFail());
      dispatch(Actions.setLoading(false));
    }
  }, [usernameExist]);

  if (props.loading) {
    return (
      <GameLoading>
        <GameLoadingText>
          <RotatingImage src="/assets/Cir.png" width={200} height={"auto"} alt="asdad" />
          <img loading="lazy" style={{ position: "absolute", translate: "-50% -50%" }} src="/assets/A.png" width={200} height={"auto"} alt="asdaasdad" />
        </GameLoadingText>
      </GameLoading>
    );
  } else {
    return (
      <>
        <RegisterBackground />
        <RegisterCard>
          <RegisterGridLeft>
            <RegisterHeader>Register</RegisterHeader>
            <div>
              <span style={{ fontSize: "1.1rem" }}>Username</span>
              <InputRegister type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
            </div>
            <div>
              <span style={{ fontSize: "1.1rem" }}>Email</span>
              <InputRegister type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div>
              <span style={{ fontSize: "1.1rem" }}>Password</span>
              <InputRegister
                style={{
                  marginTop: "10px",
                }}
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
            </div>
            <div>
              <span style={{ fontSize: "1.1rem" }}>Repeat Password</span>
              <InputRegister
                style={{
                  marginTop: "10px",
                }}
                type="password"
                value={passwordSec}
                onChange={(e) => setPasswordSec(e.target.value)}
                placeholder="Repeat password"
              />
            </div>
            <div>
              <RegisterRaceSelection>
                <RegisterRadioButton
                  inputColor={gender === "male" ? "rgb(0 252 1 / 22%)" : null}
                  onClick={() => {
                    playClickSound();
                    setGender("male");
                  }}
                >
                  Male
                </RegisterRadioButton>
                <RegisterRadioButton
                  inputColor={gender === "female" ? "rgb(0 252 1 / 22%)" : null}
                  onClick={() => {
                    playClickSound();
                    setGender("female");
                  }}
                >
                  Female
                </RegisterRadioButton>
              </RegisterRaceSelection>
            </div>
          </RegisterGridLeft>
          <RegisterGridRight>
            <SwiperContainer>
              <Swiper
                style={{ perspective: "5.5rem" }}
                effect={"coverflow"}
                ref={swiperRef}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 100,
                  modifier: 2.5,
                }}
                modules={[EffectCoverflow, Navigation]}
              >
                {imagePaths.map((imagePath, index) => (
                  <SwiperSlide key={index}>
                    <CharacterImage src={`${process.env.PUBLIC_URL}${imagePath}`} alt={`Character ${index + 1}`} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </SwiperContainer>
            <div>
              <NavigationButton onClick={goToPrevious} disabled={activeImageIndex === 0}>
                <FontAwesomeIcon size="2xl" icon={faArrowLeft} style={{ color: "#cccccc" }} />
              </NavigationButton>
              <NavigationButton onClick={goToNext} disabled={activeImageIndex === maxGenderImages - 1}>
                <FontAwesomeIcon size="2xl" icon={faArrowRight} style={{ color: "#cccccc" }} />
              </NavigationButton>
            </div>
            <ButtonRegister
              onClick={() => {
                playClickSound();
                handleRegister();
              }}
            >
              Register
            </ButtonRegister>
            <ButtonCancel
              onClick={() => {
                playClickSound();
                handleCancel();
              }}
            >
              Cancel
            </ButtonCancel>
            <div>
              <LoginButton href="/login">already have an account? LogIn here</LoginButton>
            </div>
          </RegisterGridRight>
        </RegisterCard>
      </>
    );
  }
};

function mapStateToProps(state) {
  return {
    user: state.register.register.user,
    loginFailed: state.register.register.incorrect,
    errorMessage: state.register.register.errorMessage,
    loading: state.register.register.loading,
    usernameUsed: state.register.register.usernameUsed,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...Actions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
