import { useEffect, useState } from "react";
import { ProfilePackage, ProfileBackground, ProfileStyledColumnMain, ProfileStatisticsContainer, ProfileStyledColumn, ProfileTable, ProfileButtonPlus, ImageProfileBorder, ProfileInventoryBackground, InventoryBox, ProfileMainContainer, ProfileArmorBox, ProfileRightArmorContainer, ProfileLeftArmorContainer, ProfileArmorLabel } from "../../../styles/StyledProfile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import TooltipPopup from "../../PopupsVersions/TooltipPopup";
import InfoPopup from "../../PopupsVersions/InfoPopup";
import { onDragEnd } from "./components/Inventory/InventoryFunctions";

const playClickSound = async () => {
  const clicksound = new Audio("/assets/audio/buttonClick.mp3");
  clicksound.volume = 0.09;
  clicksound.play();
};

const Inventory = ({ props, stateInventory, handleItemLeave, handleItemHover, handleMouseMove }) => {
  return (
    <>
      <ProfileInventoryBackground onMouseLeave={handleItemLeave}>
        {stateInventory.map((item, index) => (
          <Droppable droppableId={`inventory_slot_${index.toString()}`} key={index} onMouseLeave={handleItemLeave} isDropDisabled={item?.empty === false}>
            {(provided, snapshot) => {
              return (
                <InventoryBox {...provided.droppableProps} ref={provided.innerRef} onMouseEnter={() => handleItemHover(item)} onMouseLeave={handleItemLeave} onMouseMove={handleMouseMove}>
                  {item.empty === false && (
                    <Draggable draggableId={`${item.type}_${index}inv`} index={index} onMouseLeave={handleItemLeave}>
                      {(provided, snapshot) => {
                        return (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              userSelect: "none",
                              display: "flex",
                              ...provided.draggableProps.style,
                            }}
                            onMouseLeave={handleItemLeave}
                          >
                            <img loading="lazy" draggable={false} alt="altInventoryBox" src={item.image} style={{ maxWidth: "100%", height: "auto" }} onMouseLeave={handleItemLeave} />
                          </div>
                        );
                      }}
                    </Draggable>
                  )}
                  {provided.placeholder}
                </InventoryBox>
              );
            }}
          </Droppable>
        ))}
      </ProfileInventoryBackground>
    </>
  );
};

const ProfileImage = ({ props, userPic }) => (
  <ImageProfileBorder>
    <img
      loading="lazy"
      draggable={false}
      alt="altProfileImage"
      src={userPic}
      style={{
        maxWidth: "90%",
        height: "auto",
        borderRadius: "5%",
        border: "2px solid rgba(132, 77, 53, 0.5)",
        boxShadow: "2px 2px 15px rgba(0, 0, 0, 0.9)",
      }}
    />
  </ImageProfileBorder>
);

const ProfileLeftArmor = ({ props, stateSlots, handleItemLeave, handleItemHover, handleMouseMove }) => {
  return (
    <>
      <ProfileLeftArmorContainer onMouseEnter={handleItemLeave} onMouseLeave={handleItemLeave}>
        {stateSlots.map((slot, index) => {
          if (index < 6) {
            return (
              <Droppable droppableId={`${slot.type}_slot`} key={index} onMouseLeave={handleItemLeave} isDropDisabled={slot?.empty === false}>
                {(provided, snapshot) => {
                  return (
                    <ProfileArmorBox isDraggingOver={snapshot.isDraggingOver} {...provided.droppableProps} ref={provided.innerRef} onMouseEnter={() => handleItemHover(slot)} onMouseLeave={handleItemLeave} onMouseMove={handleMouseMove}>
                      {slot?.id ? (
                        <Draggable draggableId={`${slot.type}_${index}slot`} index={index} onMouseLeave={handleItemLeave}>
                          {(provided, snapshot) => {
                            return (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  userSelect: "none",
                                  display: "flex",
                                  ...provided.draggableProps.style,
                                }}
                                onMouseLeave={handleItemLeave}
                              >
                                <img loading="lazy" draggable={false} alt="altContainerLeft" src={slot.image} style={{ maxWidth: "100%", height: "auto" }} onMouseLeave={handleItemLeave} />
                              </div>
                            );
                          }}
                        </Draggable>
                      ) : (
                        <div
                          onMouseLeave={handleItemLeave}
                          style={{
                            fontSize: "60%",
                            textAlign: "center",
                            color: "gray",
                          }}
                        >
                          {slot.type}
                        </div>
                      )}
                      {provided.placeholder}
                    </ProfileArmorBox>
                  );
                }}
              </Droppable>
            );
          }
        })}
      </ProfileLeftArmorContainer>
    </>
  );
};

const ProfileRightArmor = ({ props, stateSlots, handleItemLeave, handleItemHover, handleMouseMove }) => {
  return (
    <>
      <ProfileRightArmorContainer onMouseEnter={handleItemLeave} onMouseLeave={handleItemLeave}>
        {stateSlots.map((slot, index) => {
          if (index >= 6) {
            return (
              <Droppable droppableId={`${slot.type}_slot`} key={index} onMouseLeave={handleItemLeave} isDropDisabled={slot?.empty === false}>
                {(provided, snapshot) => {
                  return (
                    <ProfileArmorBox {...provided.droppableProps} ref={provided.innerRef} onMouseEnter={() => handleItemHover(slot)} onMouseLeave={handleItemLeave} onMouseMove={handleMouseMove}>
                      {slot?.id ? (
                        <Draggable draggableId={`${slot.type}_${index}slot`} index={index} onMouseLeave={handleItemLeave}>
                          {(provided, snapshot) => {
                            return (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                onMouseLeave={handleItemLeave}
                                style={{
                                  userSelect: "none",
                                  display: "flex",
                                  ...provided.draggableProps.style,
                                }}
                              >
                                <img loading="lazy" onMouseLeave={handleItemLeave} draggable={false} alt="altContainerRight" src={slot.image} style={{ maxWidth: "100%", height: "auto" }} />
                              </div>
                            );
                          }}
                        </Draggable>
                      ) : (
                        <div
                          onMouseLeave={handleItemLeave}
                          style={{
                            fontSize: "60%",
                            textAlign: "center",
                            color: "gray",
                          }}
                        >
                          {slot.type}
                        </div>
                      )}
                      {provided.placeholder}
                    </ProfileArmorBox>
                  );
                }}
              </Droppable>
            );
          }
        })}
      </ProfileRightArmorContainer>
    </>
  );
};

const StatsUpdateForm = ({ props, handleInfoHover, handleInfoLeave, handleMouseMove }) => {
  const armorPercentage = Number(props.user.statistics.armor / props.user.level) > 50 ? 50 : Number(props.user.statistics.armor / props.user.level);
  return (
    <ProfileTable>
      <tbody>
        <tr>
          <ProfileStyledColumnMain>{"❤️   HP"}</ProfileStyledColumnMain>
          <ProfileStyledColumnMain colSpan={3} orange>
            {props.user.statistics.hp}
          </ProfileStyledColumnMain>
        </tr>
        <tr>
          <ProfileStyledColumnMain>{"⚔️   Damage"}</ProfileStyledColumnMain>
          <ProfileStyledColumnMain colSpan={3} orange>
            {props.user.statistics.attack}
          </ProfileStyledColumnMain>
        </tr>
        <tr>
          <ProfileStyledColumnMain>{"🍀   Critical"}</ProfileStyledColumnMain>
          <ProfileStyledColumnMain colSpan={3} orange>{`${props.user.statistics.critical.toFixed(1)}%`}</ProfileStyledColumnMain>
        </tr>
        <tr>
          <ProfileStyledColumnMain>{"🛡️   Armor"}</ProfileStyledColumnMain>
          <ProfileStyledColumnMain colSpan={3} orange>{`${props.user.statistics.armor} (${armorPercentage.toFixed(2)}%)`}</ProfileStyledColumnMain>
        </tr>
        <tr
          onMouseEnter={() => {
            handleInfoHover("strength");
          }}
          onMouseMove={handleMouseMove}
          onMouseLeave={() => {
            handleInfoLeave();
          }}
        >
          <ProfileStyledColumn>STRENGTH</ProfileStyledColumn>
          <ProfileStyledColumn>{props.user.statistics.strength}</ProfileStyledColumn>
          <ProfileStyledColumn>
            {props.statsButtonLoading ? (
              <FontAwesomeIcon
                icon={faSpinner}
                spinPulse
                style={{
                  color: "#a74444",
                  width: "100%",
                  minWidth: "1rem",
                  height: "2.5vh",
                }}
              />
            ) : props.user.prices.strength < props.user.eurodollar ? (
              <ProfileButtonPlus
                onClick={() => {
                  playClickSound();
                  props.handleBuyStat("strength", props.user.prices.strength);
                }}
                disabled={props.user.prices.strength > props.user.eurodollar}
              >
                +
              </ProfileButtonPlus>
            ) : (
              <></>
            )}
          </ProfileStyledColumn>
          <ProfileStyledColumn>{props.user.prices.strength}€$</ProfileStyledColumn>
        </tr>
        <tr
          onMouseEnter={() => {
            handleInfoHover("stamina");
          }}
          onMouseMove={handleMouseMove}
          onMouseLeave={() => {
            handleInfoLeave();
          }}
        >
          <ProfileStyledColumn>STAMINA</ProfileStyledColumn>
          <ProfileStyledColumn>{props.user.statistics.stamina}</ProfileStyledColumn>
          <ProfileStyledColumn>
            {props.statsButtonLoading ? (
              <FontAwesomeIcon
                icon={faSpinner}
                spinPulse
                style={{
                  color: "#a74444",
                  width: "100%",
                  minWidth: "1rem",
                  height: "2.5vh",
                }}
              />
            ) : props.user.prices.stamina < props.user.eurodollar ? (
              <ProfileButtonPlus
                onClick={() => {
                  playClickSound();
                  props.handleBuyStat("stamina", props.user.prices.stamina);
                }}
                disabled={props.user.prices.stamina > props.user.eurodollar}
              >
                +
              </ProfileButtonPlus>
            ) : (
              <></>
            )}
          </ProfileStyledColumn>
          <ProfileStyledColumn>{props.user.prices.stamina}€$</ProfileStyledColumn>
        </tr>
        <tr
          onMouseEnter={() => {
            handleInfoHover("dexterity");
          }}
          onMouseMove={handleMouseMove}
          onMouseLeave={() => {
            handleInfoLeave();
          }}
        >
          <ProfileStyledColumn>DEXTERITY</ProfileStyledColumn>
          <ProfileStyledColumn>{props.user.statistics.dexterity}</ProfileStyledColumn>
          <ProfileStyledColumn>
            {props.statsButtonLoading ? (
              <FontAwesomeIcon
                icon={faSpinner}
                spinPulse
                style={{
                  color: "#a74444",
                  width: "100%",
                  minWidth: "1rem",
                  height: "2.5vh",
                }}
              />
            ) : props.user.prices.dexterity < props.user.eurodollar ? (
              <ProfileButtonPlus
                onClick={() => {
                  playClickSound();
                  props.handleBuyStat("dexterity", props.user.prices.dexterity);
                }}
                disabled={props.user.prices.dexterity > props.user.eurodollar}
              >
                +
              </ProfileButtonPlus>
            ) : (
              <></>
            )}
          </ProfileStyledColumn>
          <ProfileStyledColumn>{props.user.prices.dexterity}€$</ProfileStyledColumn>
        </tr>
        <tr
          onMouseEnter={() => {
            handleInfoHover("intelligence");
          }}
          onMouseMove={handleMouseMove}
          onMouseLeave={() => {
            handleInfoLeave();
          }}
        >
          <ProfileStyledColumn>INTELLIGENCE</ProfileStyledColumn>
          <ProfileStyledColumn>{props.user.statistics.intelligence}</ProfileStyledColumn>
          <ProfileStyledColumn>
            {props.statsButtonLoading ? (
              <FontAwesomeIcon
                icon={faSpinner}
                spinPulse
                style={{
                  color: "#a74444",
                  width: "100%",
                  minWidth: "1rem",
                  height: "2.5vh",
                }}
              />
            ) : props.user.prices.intelligence < props.user.eurodollar ? (
              <ProfileButtonPlus
                onClick={() => {
                  playClickSound();
                  props.handleBuyStat("intelligence", props.user.prices.intelligence);
                }}
                disabled={props.user.prices.intelligence > props.user.eurodollar}
              >
                +
              </ProfileButtonPlus>
            ) : (
              <></>
            )}
          </ProfileStyledColumn>
          <ProfileStyledColumn>{props.user.prices.intelligence}€$</ProfileStyledColumn>
        </tr>
        <tr
          onMouseEnter={() => {
            handleInfoHover("lucky");
          }}
          onMouseMove={handleMouseMove}
          onMouseLeave={() => {
            handleInfoLeave();
          }}
        >
          <ProfileStyledColumn>LUCKY</ProfileStyledColumn>
          <ProfileStyledColumn>{props.user.statistics.lucky}</ProfileStyledColumn>
          <ProfileStyledColumn>
            {props.statsButtonLoading ? (
              <FontAwesomeIcon
                icon={faSpinner}
                spinPulse
                style={{
                  color: "#a74444",
                  width: "100%",
                  minWidth: "1rem",
                  height: "2.5vh",
                }}
              />
            ) : props.user.prices.lucky < props.user.eurodollar ? (
              <ProfileButtonPlus
                onClick={() => {
                  playClickSound();
                  props.handleBuyStat("lucky", props.user.prices.lucky);
                }}
                disabled={props.user.prices.lucky > props.user.eurodollar}
              >
                +
              </ProfileButtonPlus>
            ) : (
              <></>
            )}
          </ProfileStyledColumn>
          <ProfileStyledColumn>{props.user.prices.lucky}€$</ProfileStyledColumn>
        </tr>
      </tbody>
    </ProfileTable>
  );
};

const ProfileScreen = ({ props, userPic, setCursorIsHidden }) => {
  const [stateInventory, setStateInventory] = useState(props.user.inventory);
  const [stateSlots, setStateSlots] = useState(props.user.slots);
  const [popupItem, setPopupItem] = useState(null);
  const [popupInfo, setPopupInfo] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const handleMouseMove = (event) => {
    setMousePosition({ x: event.clientX, y: event.clientY });
  };
  const handleItemHover = (item) => {
    setPopupItem(item);
  };
  const handleItemLeave = () => {
    setPopupItem(null);
  };
  const handleClearPopup = () => {
    setPopupItem(null);
  };
  const handleInfoHover = (item) => {
    setPopupInfo(item);
  };
  const handleInfoLeave = () => {
    setPopupInfo(null);
  };

  //EVERYTIME IF SOMETHING WAS MOVED
  useEffect(() => {
    if (stateInventory !== props.user.inventory && stateSlots !== props.user.slots) {
      props.setLoading(true, "all");
      console.log("[info] FETCH ZMENU!!!", stateSlots, stateInventory);
      props.goUpdateInvAndSlots(stateSlots, stateInventory);
    }
  }, [stateInventory, stateSlots]);

  const updateSlotByName = (name, newItem) => {
    const index = stateSlots.findIndex((item) => item.type === name);
    if (index === -1) {
      // Item not found, do nothing
      return;
    }
    setStateSlots(
      stateSlots.map((item, i) => {
        if (i === index) {
          return newItem;
        } else {
          return item;
        }
      })
    );
  };

  const updateInventoryById = (name, newItem) => {
    const index = stateInventory.findIndex((item) => item.varId === name);
    if (index === -1) {
      // Item not found, do nothing
      console.log("[error] item not found");
      return;
    }
    setStateInventory(
      stateInventory.map((item, i) => {
        if (i === index) {
          return newItem;
        } else {
          return item;
        }
      })
    );
  };

  return (
    <ProfilePackage>
      <DragDropContext
        onDragStart={() => setCursorIsHidden(true)}
        onDragEnd={(result) => {
          onDragEnd(result, stateSlots, setStateSlots, stateInventory, setStateInventory, updateSlotByName, updateInventoryById);
          setCursorIsHidden(false);
        }}
      >
        <ProfileBackground pic="/assets/background/backgroundProfile.png" />
        {/* <ProfileFilter /> */}
        {/* UPPER PART OF DISPLAY */}
        <ProfileMainContainer style={{ justifyContent: "center" }}>
          <ProfileLeftArmor handleMouseMove={handleMouseMove} handleItemHover={handleItemHover} handleItemLeave={handleItemLeave} stateSlots={stateSlots} props={props} />
          <ProfileImage handleMouseMove={handleMouseMove} handleItemHover={handleItemHover} handleItemLeave={handleItemLeave} userPic={userPic} props={props} />
          <ProfileRightArmor handleMouseMove={handleMouseMove} handleItemHover={handleItemHover} handleItemLeave={handleItemLeave} stateSlots={stateSlots} props={props} />
        </ProfileMainContainer>
        {/* BOTTOM PART OF DISPLAY */}
        <ProfileStatisticsContainer>
          <StatsUpdateForm props={props} handleInfoHover={handleInfoHover} handleInfoLeave={handleInfoLeave} handleMouseMove={handleMouseMove} />
          <Inventory handleMouseMove={handleMouseMove} handleItemHover={handleItemHover} handleItemLeave={handleItemLeave} stateInventory={stateInventory} props={props} />
        </ProfileStatisticsContainer>
      </DragDropContext>
      {popupItem && <TooltipPopup props={props} handleClearPopup={handleClearPopup} onMouseEnter={handleItemLeave} item={popupItem} position={mousePosition} onMouseLeave={handleItemLeave} />}
      {popupInfo && <InfoPopup item={popupInfo} position={mousePosition} handleClearPopup={handleInfoLeave} onMouseLeave={handleInfoLeave} />}
    </ProfilePackage>
  );
};

export default ProfileScreen;
