import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Login from "./components/Login/Login";
import GameScene from "./components/GameScene/GameScene";
import { useEffect, useState } from "react";
import Register from "./components/Register/Register";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GameLoading, GameLoadingText, RotatingImage } from "./styles/StyledGame";

const App = () => {
  // localStorage.removeItem('user') //REMOVE user from storage
  const userLogedIn = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();

  const LoadingComponent = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      const assetPromises = [];

      console.log("[info] loading assets START");

      // Preload your assets here
      const imageUrls = ["background/backgroundLogin.png", "background/backgroundMain.png", "people/characterHumanMale.png", "people/characterElfMale.png", "people/characterDwarfMale.webp", "background/backgroundProfile.png", "people/questMan1.png"];
      imageUrls.forEach((url) => {
        const img = new Image();
        img.src = `/assets/${url}`;
        const promise = new Promise((resolve, reject) => {
          console.log("[loading] ---> [", url, "]");
          img.onload = resolve;
          img.onerror = reject;
        });

        assetPromises.push(promise);
      });

      Promise.all(assetPromises)
        .then(() => {
          console.log("[info] loading assets END");
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("[error] loading assets:", error);
          setIsLoading(false);
        });
    }, []);

    return isLoading ? (
      <GameLoading>
        <GameLoadingText>
          <RotatingImage src="/assets/Cir.png" width={200} height={"auto"} alt="asdad" />
          <img style={{ position: "absolute", translate: "-50% -50%" }} src="/assets/A.png" width={200} loading="lazy" height={"auto"} alt="asdaasdad" />
        </GameLoadingText>
      </GameLoading>
    ) : (
      children
    );
  };

  useEffect(() => {
    console.log("[info] APP START");
    console.log("[info] storage user: ", userLogedIn);
  }, []);

  return (
    <>
      <LoadingComponent>
        <div>
          <Switch>
            {userLogedIn ? (
              <>
                <Route exact path="/game" name="Game Scene" render={(props) => <GameScene {...props} />} />
                {location?.pathname !== "/game" && <Redirect to="/game" />}
              </>
            ) : (
              <>
                <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
                <Route exact path="/register" name="Register Page" render={(props) => <Register {...props} />} />
                {location?.pathname !== "/register" && location?.pathname !== "/login" && <Redirect to="/login" />}
              </>
            )}
          </Switch>
          <ToastContainer
            toastStyle={{
              backgroundColor: "rgba(63, 38, 28, 0.95)",
              border: "0.12rem solid rgba(43, 26, 19, 1)",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.8)",
              cursor: "url('/assets/mousePointer.png'), auto",
              userSelect: "none",
            }}
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
        </div>
      </LoadingComponent>
    </>
  );
};

export default App;
