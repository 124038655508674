import { useState, useEffect } from "react";
import { FightContainer, FightEnemySide, FightFrameContainer, FightFrameEnemy, FightFrameLine, FightFramePlayer, FightHpDeficitEnemy, FightHpDeficitUser, FightHpFill, FightHpFrame, FightHpLabel, FightNameLabel, FightPicture, FightPlayerSide, FightStatColumn, FightStatTable, FightVsText, PubBackground, PubPackage } from "../../../styles/StyledPub";
import { toast } from "react-toastify";

const ArenaScreen = ({ props, userPic, arenaEnemy, arenaFight }) => {
  const [fight, setFight] = useState({
    userHp: 100,
    userHpMax: 100,
    userAttack: 0,
    enemyHp: 100,
    enemyHpMax: 100,
    enemyAttack: 0,
    critical: false,
  });
  const [shakingEnemy, setShakingEnemy] = useState(false);
  const [shakingPlayer, setShakingPlayer] = useState(false);
  const [fightLoopStarted, setFightLoopStarted] = useState(false);
  const [fightPlayerMargin, setFightPlayerMargin] = useState(0);
  const [fightEnemyMargin, setFightEnemyMargin] = useState(0);

  let userHpPercent = props.user.quest_status === "arena" ? (fight.userHp / props.arenaUser.statistics.hp) * 100 : 100;
  let enemyHpPercent = props.user.quest_status === "arena" ? (fight.enemyHp / props.arenaEnemy.statistics.hp) * 100 : 100;

  //START FIGHT
  //if was not already started
  useEffect(() => {
    if (props.user.quest_status === "arena" && !fightLoopStarted) {
      console.log("[fight] START fight loop. ARENA ");

      setFight({
        userHp: props.arenaUser.statistics.hp,
        userHpMax: props.arenaUser.statistics.hp,
        userAttack: 0,
        enemyHp: props.arenaEnemy.statistics.hp,
        enemyHpMax: props.arenaEnemy.statistics.hp,
        enemyAttack: 0,
        critical: false,
      });

      setTimeout(() => {
        setFightLoopStarted(true);
      }, 1000); //LOONGER FOR EDIT STYLE
    }
  }, [props.user.quest_status]);

  useEffect(() => {
    let fightIntervalId;
    if (fightLoopStarted) {
      console.log("[info] REAL FIGHT STARTED");
      let fightIndex = 0;
      fightIntervalId = setInterval(() => {
        const fightAttack = props.arenaFight.fightLog[fightIndex];

        if (fightAttack.who === props.user.username) {
          //MOVE TO ENEMY________________________
          for (let i = -10; i <= 45; i++) {
            setTimeout(() => {
              setFightPlayerMargin(i);
              if (i === 45) {
                for (let i = 45; i >= 0; i--) {
                  setTimeout(() => {
                    setFightPlayerMargin(i);
                  }, (45 - i) * 15);
                }
              }
            }, i * 1.8);
          }
          //______________________________________

          setFight((prevFight) => ({
            ...prevFight,
            userAttack: fightAttack.damage,
            enemyHp: fightAttack.hp,
            enemyAttack: 0,
            critical: fightAttack.critical,
          }));
          if (fightAttack.critical === true) {
            const hitCritic = new Audio("/assets/audio/hitCritic.mp3");
            hitCritic.volume = 0.05;
            hitCritic.play();
          } else {
            const hitDagger = new Audio("/assets/audio/hitDagger.mp3");
            hitDagger.volume = 0.05;
            hitDagger.play();
          }
        } else {
          //MOVE TO PLAYER___________________
          for (let i = -10; i <= 45; i++) {
            setTimeout(() => {
              setFightEnemyMargin(i);
              if (i === 45) {
                for (let i = 45; i >= 0; i--) {
                  setTimeout(() => {
                    setFightEnemyMargin(i);
                  }, (45 - i) * 15);
                }
              }
            }, i * 1.8);
          }
          //_________________________________

          setFight((prevFight) => ({
            ...prevFight,
            enemyAttack: fightAttack.damage,
            userHp: fightAttack.hp,
            userAttack: 0,
            critical: fightAttack.critical,
          }));
          if (fightAttack.critical === true) {
            const hitCritic = new Audio("/assets/audio/hitCritic.mp3");
            hitCritic.volume = 0.05;
            hitCritic.play();
          } else {
            const hitSword = new Audio("/assets/audio/hitSword.mp3");
            hitSword.volume = 0.05;
            hitSword.play();
          }
        }

        if (fightAttack.hp === 0) {
          //END OF FIGHT
          console.log("[END] winner: ", props.arenaFight.whoWin, ", you get: ", props.arenaFight.youGet.honors);
          if (props.arenaFight.whoWin === props.user.username) {
            const questWin = new Audio("/assets/audio/questWin.mp3");
            questWin.volume = 0.05;
            questWin.play();
            toast("You won!");
            toast.info(`Received +${props.arenaFight.youGet.honors} honors`);
            toast.info(`Received +${props.arenaFight.youGet.eurodollar}€$`);
          } else {
            const questLost = new Audio("/assets/audio/questLost.mp3");
            questLost.volume = 0.05;
            questLost.play();
            toast.error("You lost!");
            toast.info(`Lost -${props.arenaFight.youGet.honors} honors`);
            toast.info(`Lost -${props.arenaFight.youGet.eurodollar}€$`);
          }
          setFightLoopStarted(false);
          clearInterval(fightIntervalId);
          props.onFinishArenaFight();
        } else {
          //can repeat attacks
          fightIndex = fightIndex + 1;
        }
      }, 1000);
    }

    return () => {
      clearInterval(fightIntervalId);
    };
  }, [fightLoopStarted]);

  //SHAKE ENEMY
  useEffect(() => {
    if (fight.userAttack !== 0) {
      setTimeout(() => {
        setShakingEnemy(true);
        setTimeout(() => {
          setShakingEnemy(false);
        }, 500); // Reset shaking state after 0.5s (same duration as the animation)
      }, 125);
    }
  }, [fight.userAttack]);

  //SHAKE PLAYER
  useEffect(() => {
    if (fight.enemyAttack !== 0) {
      setTimeout(() => {
        setShakingPlayer(true);
        setTimeout(() => {
          setShakingPlayer(false);
        }, 500); // Reset shaking state after 0.5s (same duration as the animation)
      }, 125);
    }
  }, [fight.enemyAttack]);

  const enemyPic = props.user.quest_status === "arena" && `/assets/${props.arenaEnemy.gender}/${props.arenaEnemy.gender}Image${props.arenaEnemy.character}.png`;

  return (
    <PubPackage>
      {props.user.quest_status === "arena" ? (
        <>
          <PubBackground />
          {/* FightContainer */}
          <FightContainer arena>
            <FightPlayerSide>
              <FightFramePlayer shaking={shakingPlayer} margin={`${fightPlayerMargin}%`}>
                <FightFrameContainer>
                  <FightPicture image={userPic} />
                  <FightHpFrame widthPercent={userHpPercent}>
                    <FightHpFill widthPercent={userHpPercent}></FightHpFill>
                  </FightHpFrame>
                  <FightNameLabel>{props.user.username_visible}</FightNameLabel>
                  <FightFrameLine />
                  <FightHpLabel>{`hp: ${fight.userHp}/${props.arenaUser.statistics.hp}`}</FightHpLabel>
                  <FightStatTable>
                    <tbody>
                      <tr>
                        <FightStatColumn>strength</FightStatColumn>
                        <FightStatColumn>{props.arenaUser.statistics.strength}</FightStatColumn>
                      </tr>
                      <tr>
                        <FightStatColumn>stamina</FightStatColumn>
                        <FightStatColumn>{props.arenaUser.statistics.stamina}</FightStatColumn>
                      </tr>
                      <tr>
                        <FightStatColumn>intelligence</FightStatColumn>
                        <FightStatColumn>{props.arenaUser.statistics.intelligence}</FightStatColumn>
                      </tr>
                      <tr>
                        <FightStatColumn>dexterity</FightStatColumn>
                        <FightStatColumn>{props.arenaUser.statistics.dexterity}</FightStatColumn>
                      </tr>
                      <tr>
                        <FightStatColumn>lucky</FightStatColumn>
                        <FightStatColumn>{props.arenaUser.statistics.lucky}</FightStatColumn>
                      </tr>
                    </tbody>
                  </FightStatTable>
                </FightFrameContainer>
              </FightFramePlayer>
              {fight.enemyAttack !== 0 && <FightHpDeficitUser critical={fight.critical ? "red" : null} criticalSize={fight.critical ? "700%" : null}>{`-${fight.enemyAttack}`}</FightHpDeficitUser>}
            </FightPlayerSide>
            <FightEnemySide>
              <FightFrameEnemy shaking={shakingEnemy} margin={`${fightEnemyMargin}%`}>
                <FightFrameContainer>
                  {/* change the photo to assets */}
                  <FightPicture image={enemyPic} />
                  <FightHpFrame widthPercent={enemyHpPercent}>
                    <FightHpFill widthPercent={enemyHpPercent}></FightHpFill>
                  </FightHpFrame>
                  <FightNameLabel>{props.arenaEnemy.name !== "" ? props.arenaEnemy.name : "nobody"}</FightNameLabel>
                  <FightFrameLine />
                  <FightHpLabel>{`hp: ${fight.enemyHp}/${fight.enemyHpMax}`}</FightHpLabel>
                  <FightStatTable>
                    <tbody>
                      <tr>
                        <FightStatColumn>strength</FightStatColumn>
                        <FightStatColumn>{props.arenaEnemy?.statistics?.strength}</FightStatColumn>
                      </tr>
                      <tr>
                        <FightStatColumn>stamina</FightStatColumn>
                        <FightStatColumn>{props.arenaEnemy?.statistics?.stamina}</FightStatColumn>
                      </tr>
                      <tr>
                        <FightStatColumn>intelligence</FightStatColumn>
                        <FightStatColumn>{props.arenaEnemy?.statistics?.intelligence}</FightStatColumn>
                      </tr>
                      <tr>
                        <FightStatColumn>dexterity</FightStatColumn>
                        <FightStatColumn>{props.arenaEnemy?.statistics?.dexterity}</FightStatColumn>
                      </tr>
                      <tr>
                        <FightStatColumn>lucky</FightStatColumn>
                        <FightStatColumn>{props.arenaEnemy?.statistics?.lucky}</FightStatColumn>
                      </tr>
                    </tbody>
                  </FightStatTable>
                </FightFrameContainer>
              </FightFrameEnemy>
              <FightVsText>
                {" "}
                <img loading="lazy" style={{ userSelect: "none", userDrag: "none" }} draggable={false} width={"50%"} height={"50%"} src="/assets/VSLabel.png" alt="adas" />
              </FightVsText>
              {fight.userAttack !== 0 && <FightHpDeficitEnemy critical={fight.critical ? "red" : null} criticalSize={fight.critical ? "700%" : null}>{`-${fight.userAttack}`}</FightHpDeficitEnemy>}
            </FightEnemySide>
          </FightContainer>
        </>
      ) : (
        <center>
          <h1>select user in leaderboards to attack...</h1>
          <img loading="lazy" width={"25%"} draggable={false} alt="altWorkInProgress" src={"/assets/workInProgress.png"} />
        </center>
      )}
    </PubPackage>
  );
};

export default ArenaScreen;
