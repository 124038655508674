import { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as Actions from "./store/actions";
import { WelcomeImage, ButtonLogin, ButtonCancel, InputLogin, LoginCard, LoginHeader, RegisterButton, LoginBackground, ButtonWelcome, WelcomeLabel } from "../../styles/StyledLogin";
import { GameLoading, GameLoadingText, RotatingImage } from "../../styles/StyledGame";

const Login = (props) => {
  const passwordInputRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const userExists = props.user.email ? true : false;
  const userFail = props.loginFailed;
  const backgroundAudioRef = useRef();
  const [welcome, setWelcome] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [welcomeVisible, setWelcomeVisible] = useState(false);

  function handleCancel() {
    window.open("/", "_self");
  }

  //did mount
  useEffect(() => {
    const timer = setTimeout(() => {
      setWelcomeVisible(true);
    }, 1000);

    return () => clearTimeout(timer);
  });

  //Login Successful
  useEffect(() => {
    dispatch(Actions.setLoading(true));
    if (userExists) {
      const userSaved = {
        username: props.user.username,
        key: props.user.key,
      };
      if (props.user.ban) {
        localStorage.removeItem("user");
        toast.error(`You are banned! Reason: ${props.user.ban_reason}`);
        dispatch(Actions.doResetFail());
        setPassword("");
        dispatch(Actions.setLoginButtonDisabled(false));
      } else {
        localStorage.setItem("user", JSON.stringify(userSaved));
        dispatch(Actions.setLoading(false));
        toast(`Login successful! Welcome ${props.user.username}`);
        setTimeout(() => {
          window.open("/game", "_self");
          dispatch(Actions.setLoginButtonDisabled(false));
        }, 2000);
        setUsername("");
        setPassword("");
      }
    }
  }, [userExists]);

  //Wrong Username of Password
  useEffect(() => {
    if (userFail) {
      localStorage.removeItem("user");
      toast.error(props.errorMessage);
      dispatch(Actions.doResetFail());
      setPassword("");
      dispatch(Actions.setLoading(false));
    }
  }, [userFail]);

  //LOGIN
  const handleLogin = () => {
    if (username?.length >= 4) {
      dispatch(Actions.doLogin(username, password));
    } else {
      toast.error("Username is small");
    }
  };

  const handleInputKeyPress = (event) => {
    if (event.key === "Enter") {
      const clicksound = new Audio("/assets/audio/buttonClick.mp3");
      clicksound.volume = 0.09;
      clicksound.play();
      handleLogin();
    }
  };

  if (props.loading) {
    return (
      <GameLoading>
        <GameLoadingText>
          <RotatingImage src="/assets/Cir.png" width={200} height={"auto"} alt="asdad" />
          <img loading="lazy" style={{ position: "absolute", translate: "-50% -50%" }} src="/assets/A.png" width={200} height={"auto"} alt="asdaasdad" />
        </GameLoadingText>
      </GameLoading>
    );
  } else {
    return (
      <>
        <LoginBackground />
        <LoginCard>
          {welcome === true ? (
            <>
              <LoginHeader>Login</LoginHeader>
              <div>
                <div className="field">
                  <span className="fa fa-user">Username</span>
                  <InputLogin type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} required />
                </div>
                <div className="field">
                  <span className="fa fa-lock">Password</span>
                  <InputLogin
                    ref={passwordInputRef}
                    onKeyPress={handleInputKeyPress}
                    style={{
                      marginTop: "10px",
                    }}
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    required
                  />
                </div>

                <ButtonLogin
                  hidden={props.loginButtonDisabled}
                  onClick={() => {
                    const clicksound = new Audio("/assets/audio/buttonClick.mp3");
                    clicksound.volume = 0.09;
                    clicksound.play();
                    handleLogin();
                  }}
                >
                  Log In
                </ButtonLogin>
                <ButtonCancel
                  hidden={props.loginButtonDisabled}
                  onClick={() => {
                    const clicksound = new Audio("/assets/audio/buttonClick.mp3");
                    clicksound.volume = 0.09;
                    clicksound.play();
                    handleCancel();
                  }}
                >
                  Cancel
                </ButtonCancel>
              </div>
              <RegisterButton href="/register">Don't have an account? Register here</RegisterButton>
            </>
          ) : (
            <>
              <WelcomeImage visible={welcomeVisible} width={"60%"} draggable={false} alt="altWelcome" src={"/assets/welcomeImage.png"} />
              <ButtonWelcome
                onClick={() => {
                  const clicksound = new Audio("/assets/audio/buttonClick.mp3");
                  clicksound.volume = 0.09;
                  clicksound.play();
                  setWelcome(true);
                  const loginBackmusic = new Audio("/assets/audio/loginBackmusic.mp3");
                  loginBackmusic.volume = 0.05;
                  loginBackmusic.loop = true;
                  loginBackmusic.play();
                }}
              >
                Welcome
              </ButtonWelcome>
            </>
          )}
        </LoginCard>
      </>
    );
  }
};

function mapStateToProps(state) {
  return {
    user: state.login.login.user,
    loginButtonDisabled: state.login.login.loginButtonDisabled,
    loginFailed: state.login.login.incorrect,
    errorMessage: state.login.login.errorMessage,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...Actions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
