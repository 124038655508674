const playGetSound = async () => {
  const putsound = new Audio("/assets/audio/itemGet.mp3");
  putsound.volume = 0.07;
  putsound.play();
};

const playSellSound = async () => {
  const putsound = new Audio("/assets/audio/itemSell.mp3");
  putsound.volume = 0.07;
  putsound.play();
};

export const onDragEnd = (result, stateSlots, setStateSlots, stateInventory, setStateInventory, updateSlotByName, updateInventoryById, itemsFromShop, setInteractedItem, setInteraction, handleSellingItem) => {
  if (!result.destination) return;
  const { source, destination } = result;
  if (source.droppableId !== destination.droppableId) {
    console.log("[DROP] source: ", source.droppableId);
    console.log("[DROP] destination: ", destination.droppableId);

    if (destination.droppableId.includes("inventory_slot") && source.droppableId.includes("inventory_slot")) {
      //___________FROM INVENTORY TO DIFFERENT INVENTORY
      console.log("[drag] from inv to inv");
      const whereMatch = destination.droppableId.match(/\d+$/);
      const whereIndex = whereMatch[0];
      const fromMatch = source.droppableId.match(/\d+$/);
      const fromIndex = fromMatch[0];
      const whereInv = stateInventory[whereIndex];
      if (whereInv.empty === true) {
        const whatToTake = stateInventory[fromIndex];
        console.log("TAKING ITEM BY INDEX: ", fromIndex);
        playGetSound();
        setStateInventory((prevArray) => {
          const newArray = [...prevArray];
          newArray[whereIndex] = whatToTake;
          newArray[fromIndex] = {
            empty: true,
            name: "",
            type: "",
            id: "",
            varId: "",
            image: "",
            stats: {
              armor: 0,
              stamina: 0,
              strength: 0,
              dexterity: 0,
              lucky: 0,
              weapon_damage_min: 0,
              weapon_damage_max: 0,
              intelligence: 0,
            },
          };
          return newArray;
        });
      }
    } else if (destination.droppableId.includes("inventory_slot") && !source.droppableId.includes("shop_slot")) {
      //___________FROM SLOT TO INVENTORY
      console.log("[drag] from slot to inv");
      const match = destination.droppableId.match(/\d+$/);
      const lastNumber = match[0];
      const inventoryItemBefore = stateInventory[lastNumber];
      if (inventoryItemBefore.empty === true) {
        const stateType = source.droppableId.split("_")[0];
        const whatToTake = stateSlots.find((obj) => obj.type === stateType);
        playGetSound();
        setStateInventory((prevArray) => {
          const newArray = [...prevArray];
          newArray[lastNumber] = whatToTake;
          return newArray;
        });
        updateSlotByName(stateType, { type: stateType, empty: true });
      }
    } else if (destination.droppableId.includes("inventory_slot") && source.droppableId.includes("shop_slot")) {
      //___________FROM SHOP TO INVENTORY
      console.log("[drag] from shop to inv");
      const match = destination.droppableId.match(/\d+$/);
      const lastNumber = match[0];
      const inventoryItemBefore = stateInventory[lastNumber];
      if (inventoryItemBefore.empty === true) {
        const stateIndex = source.droppableId.split("_")[2];
        console.log("itemsFromShop: ", itemsFromShop);
        const whatToTake = itemsFromShop[Number(stateIndex)];
        console.log("[info] wwhatToTake ", whatToTake);
        playGetSound();
        setInteractedItem(whatToTake);
        setInteraction("buy");
        setStateInventory((prevArray) => {
          const newArray = [...prevArray];
          newArray[lastNumber] = whatToTake;
          return newArray;
        });
      }
    } else if (destination.droppableId.includes("seller_slot")) {
      //___________FROM INVENTORY TO SELLER
      console.log("[drag] from inv to sell");
      const match = source.droppableId.match(/\d+$/);
      const lastNumber = match?.[0];
      console.log("inventory item for sell ", stateInventory[lastNumber]);
      playSellSound(); //change to sell sound
      setInteractedItem(stateInventory[lastNumber]);
      setInteraction("sell");
      updateInventoryById(stateInventory[lastNumber].varId, {
        empty: true,
        name: "",
        type: "",
        id: "",
        varId: "",
        image: "",
        stats: {
          armor: 0,
          stamina: 0,
          strength: 0,
          dexterity: 0,
          lucky: 0,
          weapon_damage_min: 0,
          weapon_damage_max: 0,
          intelligence: 0,
        },
      });
    } else if (destination.droppableId.includes("_slot")) {
      //___________FROM INVENTORY TO SLOT
      console.log("[drag] from inv to slot");
      const destinationDroppableId = destination.droppableId.replace("_slot", "");
      const match = source.droppableId.match(/\d+$/);
      const lastNumber = match?.[0];
      console.log("Some last number? ", lastNumber);
      const isSlotEmpty = stateSlots.find((obj) => obj?.type === stateInventory[lastNumber]?.type);
      console.log("inventory item? ", stateInventory[lastNumber]);
      if (stateInventory[lastNumber]?.type === destinationDroppableId && (isSlotEmpty?.empty === true || isSlotEmpty?.empty === undefined)) {
        playGetSound();
        updateSlotByName(destinationDroppableId, stateInventory[lastNumber]);
        updateInventoryById(stateInventory[lastNumber].varId, {
          empty: true,
          name: "",
          type: "",
          id: "",
          varId: "",
          image: "",
          stats: {
            armor: 0,
            stamina: 0,
            strength: 0,
            dexterity: 0,
            lucky: 0,
            weapon_damage_min: 0,
            weapon_damage_max: 0,
            intelligence: 0,
          },
        });
      }
    }
  } else {
    console.log("[DROP] source: ", source.droppableId);
    console.log("[DROP] destination: ", destination.droppableId);
    console.log("[DROP] ERROR -> SAME SLOT");
  }
};
