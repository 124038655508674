import React from "react";
import styled from "styled-components";
import { ItemName, PopupContainer, PopupContent, PopupStat, PopupStats, PopupImage } from "../../styles/StyledPopupItem";
import { ProfileEurodollar } from "../../styles/StyledGame";

const TooltipPopup = ({ item, position, handleClearPopup, props }) => {
  if (item?.empty === false) {
    const userSlot = props.user.slots.find((obj) => obj.type === item.type);
    let statsCompare;
    let missingStats = [];
    if (userSlot.varId !== item.varId) {
      const { stats: statsObject1 } = userSlot;
      const { stats: statsObject2 } = item;

      statsCompare = {
        armor: statsObject2.armor - (statsObject1 ? statsObject1.armor : 0),
        stamina: statsObject2.stamina - (statsObject1 ? statsObject1.stamina : 0),
        lucky: statsObject2.lucky - (statsObject1 ? statsObject1.lucky : 0),
        strength: statsObject2.strength - (statsObject1 ? statsObject1.strength : 0),
        intelligence: statsObject2.intelligence - (statsObject1 ? statsObject1.intelligence : 0),
        weapon_damage_min: statsObject2.weapon_damage_min - (statsObject1 ? statsObject1.weapon_damage_min : 0),
        weapon_damage_max: statsObject2.weapon_damage_max - (statsObject1 ? statsObject1.weapon_damage_max : 0),
        dexterity: statsObject2.dexterity - (statsObject1 ? statsObject1.dexterity : 0),
      };

      for (const key in statsObject2) {
        try {
          if (key !== "weapon_damage_max" && key !== "weapon_damage_min" && key !== "armor" && statsObject2[key] == 0 && statsObject1[key] !== 0) {
            missingStats.push({ key: key, value: statsObject1[key] });
          }
        } catch (e) {}
      }
    } else {
      statsCompare = null;
    }

    // console.log('missingStats: ', missingStats)
    const stats = Object.entries(item.stats).map(([key, value]) => {
      if (value > 0) {
        if (key.toString() === "weapon_damage_max" || key.toString() === "weapon_damage_min" || key.toString() === "armor") {
          <span key={key}></span>;
        } else {
          return (
            <PopupStat key={key}>
              <strong style={{ color: key === "armor" ? "rgba(233, 212, 196, 0.8)" : "black" }}>{key}:</strong> {value}
              {statsCompare?.[key] !== undefined && statsCompare?.[key] !== 0 ? (
                <>
                  {" {"}
                  <span
                    style={{
                      color: statsCompare[key] >= 0 ? "green" : "#c10000",
                    }}
                  >
                    {statsCompare[key] >= 0 ? "+" : ""}
                    {statsCompare[key]}
                  </span>
                  {"}"}
                </>
              ) : (
                ""
              )}
            </PopupStat>
          );
        }
      }
    });

    const armorStat = (armor) => {
      if (armor) {
        return (
          <PopupStat>
            <strong style={{ color: "rgba(233, 212, 196, 0.8)" }}>armor:</strong> {armor}
            {statsCompare?.["armor"] !== undefined && statsCompare["armor"] !== 0 ? (
              <>
                {" {"}
                <span
                  style={{
                    color: statsCompare["armor"] >= 0 ? "green" : "#c10000",
                  }}
                >
                  {statsCompare["armor"] >= 0 ? "+" : ""}
                  {statsCompare["armor"]}
                </span>
                {"}"}
              </>
            ) : (
              ""
            )}
          </PopupStat>
        );
      }
    };

    const weaponDamage = (min, max) => {
      const prumer = Math.floor((min + max) / 2);
      let prumerChange = null;
      if (statsCompare?.weapon_damage_min !== 0 || statsCompare?.weapon_damage_max !== 0) {
        prumerChange = Math.floor((statsCompare?.weapon_damage_min + statsCompare?.weapon_damage_max) / 2);
      }
      if (Number(min) > 0) {
        return (
          <PopupStat>
            <strong style={{ color: "rgba(233, 212, 196, 0.8)" }}>damage:</strong>
            {`(${min}-${max}) ~${prumer}`}{" "}
            {typeof prumerChange === "number" && !isNaN(prumerChange) && prumerChange !== 0 ? (
              <>
                {" {"}
                <span
                  style={{
                    color: prumerChange < 0 ? "#c10000" : "green",
                  }}
                >
                  {prumerChange >= 0 ? "+" : ""}
                  {prumerChange}
                </span>
                {"}"}
              </>
            ) : (
              ""
            )}
          </PopupStat>
        );
      }
    };

    return (
      <PopupContainer
        onMouseEnter={() => {
          try {
            handleClearPopup();
          } catch (e) {}
        }}
        position={position}
      >
        <PopupContent>
          <PopupImage src={item.image} alt={item.name} />
          <PopupStats>
            <PopupStat>
              <ItemName>{item.name}</ItemName>
            </PopupStat>
            {weaponDamage(item?.stats?.weapon_damage_min, item?.stats?.weapon_damage_max)}
            {armorStat(item?.stats?.armor)}
            {stats}
            {missingStats.length > 0 && (
              <div>
                {missingStats.map((stat, i) => (
                  <PopupStat key={i}>
                    <strong>
                      <span style={{ color: "#220000f2" }}>{`- ${stat.key}`}:</span> 0{" "}
                    </strong>
                    <span style={{ color: "#c10000" }}> -{stat.value}</span>
                  </PopupStat>
                ))}
              </div>
            )}
          </PopupStats>
        </PopupContent>
        <div style={{ position: "absolute", bottom: "2%", right: "2%" }}>
          <span style={{ textShadow: "0 2px 5px rgba(0, 0, 0, 0.8)", color: "gold", fontSize: "0.80rem" }}>
            {" "}
            {item.price && `${item.price} `}
            <ProfileEurodollar>€$</ProfileEurodollar>
          </span>
        </div>
      </PopupContainer>
    );
  }
};

export default TooltipPopup;
