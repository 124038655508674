import styled, { css, keyframes } from "styled-components";
import LazyLoad from "react-lazyload";

const glowAnimation = keyframes`
  0% {
    box-shadow: inset 0 0 0 0 rgba(255, 113, 0, 0);
  }
  100% {
    box-shadow: inset 0 0 10px 5px rgba(255, 113, 0, 0.5);
  }
`;

export const ProfileMainContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  font-weight: bold;
  color: white;
  flex: 1;
  height: 70%;
  display: flex;
  justify-content: space-evenly; //How boxes are ordered.
  align-items: center;
`;

export const ImageProfileBorder = styled(LazyLoad)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-image: url("/assets/background/woodenBackground.png");
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 1); /* Optional: Add a shadow effect */
  background-color: rgba(39, 23, 16, 0.6);
  width: 60%;
  height: auto;
  border: 2px solid rgba(132, 77, 53, 0.5);
  border-radius: 5%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
`;

export const ProfileLeftArmorContainer = styled.div`
  background-color: rgba(66, 40, 30, 0.5);
  width: 11%;
  height: 94%;
  margin-right: 3%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 2px solid rgba(132, 77, 53, 0.5);
  border-radius: 1%;
  padding: 0.2rem;
`;

export const ProfileRightArmorContainer = styled.div`
  background-color: rgba(66, 40, 30, 0.5);
  width: 11%;
  height: 94%;
  margin-left: 3%;
  border: 2px solid rgba(132, 77, 53, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 1%;
  padding: 0.2rem;
`;

export const ProfileArmorBox = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  margin-top: 2.5%;
  height: 100%;
  transition: box-shadow 0.5s;

  &:hover {
    animation: ${glowAnimation} 1s infinite alternate;
  }
`;

export const ProfileTable = styled.table`
  float: right;
  color: white;
  width: 50%;
  font-size: 1.7vh;
  margin-top: 2%;
`;

export const ProfileStyledColumn = styled.td`
  padding: 1vh;
  border: 1px solid black;
  color: ${(props) => (props?.orange ? "rgba(255, 116, 0, 0.8)" : null)};
  background-color: rgba(0, 0, 0, 0.5);
`;

export const LeaderBoardTable = styled.table`
  width: 100%;
  background-color: rgba(90, 62, 43, 0.5);
  border-collapse: collapse;
  border-radius: 5%;
`;

export const LeaderBoardThead = styled.thead`
  background-color: rgba(67, 47, 38, 0.8);
`;

export const LeaderBoardTh = styled.th`
  padding: 8px;
  border: 1px solid rgba(67, 47, 38, 0.8);
`;

export const LeaderBoardTr = styled.tr`
  max-height: 12px;
  &:hover {
    background-color: ${(props) => !props.noHover && "rgba(179, 113, 98, 0.2)"};
  }
`;

export const ListUserImage = styled.img`
  border-radius: 25%;
  user-select: none;
`;

export const LeaderBoardTd = styled.td`
  padding: ${(props) => (props.noPadding ? "0px" : "8px")};
  border: 1px solid rgba(67, 47, 38, 0.8);

  ${(props) =>
    props.isSelectedUser &&
    css`
      background-color: rgba(129, 95, 82, 0.8);
    `}

  ${(props) =>
    props.isUser &&
    css`
      background-color: rgba(109, 75, 62, 0.8);
    `};
`;

export const LeaderBoardImage = styled.div`
  border-radius: 25%;
  user-select: none;
  border: ${(props) => (props?.rankColor ? props.rankColor : "0.1rem solid rgba(67, 47, 38, 0.8)")};
  height: 3rem;
  width: 3rem;
`;

export const LeaderBoardMoveButton = styled.button`
  color: rgb(12, 12, 12);
  font-size: 21px;
  padding: 2px;
  cursor: url("/assets/mousePointer.png"), auto;
  font-weight: bold;
  border: 0.2rem solid rgba(67, 47, 38, 1);
  background: rgba(105, 105, 105, 0.2);
  outline: none;
  border-radius: 8px;
`;

export const LeaderBoardRank = styled.p`
  font-weight: bold;
  color: ${(props) => (props?.rankColor ? props.rankColor : "#3f4d4c")};
  padding-left: 0.5rem;
  font-size: ${(props) => (props?.rankSize ? props.rankSize : "1rem")};
  margin: 0;
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 1);
`;

export const LeaderBoardPackage = styled.div`
  display: flex;
  position: absolute;
  background-color: rgb(49 27 13 / 75%);
  width: 78.9%;
  height: 100%;
  z-index: 1;
`;

export const SplitScreenContainer = styled.div`
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 3;
`;
export const SplitScreenLeft = styled.div`
  flex: 1;
  background-color: rgba(0, 0, 55, 0.1);
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 2px solid #552e28;
`;

export const SplitScreenRight = styled.div`
  flex: 1;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 4;
  display: flex;
  flex-direction: column; /* Add this line to stack divs vertically */
  justify-content: center;
  align-items: center;
`;

export const ProfileNameLabelStyle = styled.a`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: white;
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 1);
`;

export const LeaderBoardList = styled.div`
  width: 90%;
  height: 90%;
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  justify-content: center;
  flex-direction: column;
  border-radius: 2%;
  z-index: 5;
  background-color: rgba(34, 13, 9, 0.2);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 1); /* Optional: Add a shadow effect */
`;

export const LeaderBoardButton = styled.button`
  margin-top: 3%;
  margin-left: 5%;
  color: #dccbbe;
  width: 40%;
  min-height: 2rem;
  filter: blur(0.005rem);
  background-color: ${(props) => (props.selected ? "rgba(85, 46, 40, 1)" : "rgba(117, 63, 56, 1)")};
  flex-grow: 1;
  border-radius: 5px;
  border: 2px solid #8c5d37; /* Adjust the color to match the wooden plank style */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 1); /* Optional: Add a shadow effect */
  outline: none;
  cursor: url("/assets/mousePointer.png"), auto;
  font-weight: bold;
  font-size: 20px;
  z-index: 3;
  height: 5%;
  top: 92%;
  right: 10%;
  &:hover {
    background-color: ${(props) => (props.selected ? "rgba(85, 46, 40, 1)" : "rgba(179, 113, 98, 1)")};
    transform: scale(1.1);
    letter-spacing: 0.1rem;
  }
`;
