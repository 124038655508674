import React, { useRef, useEffect } from "react";
import { ItemName, PopupContainer, PopupContent, PopupStat, PopupStats } from "../../styles/StyledPopupInfo";

const InfoPopup = ({ item, position, handleClearPopup }) => {
  const popupRef = useRef(null);

  const InfoDetail = ({ item }) => {
    if (item === "strength") {
      return <span>Increases your basic damage</span>;
    } else if (item === "stamina") {
      return <span>Increases your HP</span>;
    } else if (item === "dexterity") {
      return <span>The higher it is, the more chance there is to start fight</span>;
    } else if (item === "intelligence") {
      return <span>Increases the effect of your talent spells</span>;
    } else if (item === "lucky") {
      return <span>Increases your critical chance. Max 50%</span>;
    }
  };

  //MAKE POSITION ON TOP
  useEffect(() => {
    const popupElement = popupRef.current;
    const popupRect = popupElement.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    const newTopPosition = position.y - popupRect.height - 10;
    const isPopupAbove = newTopPosition > 0;
    const isOverflowing = popupRect.bottom > windowHeight;

    if (isPopupAbove || isOverflowing) {
      // Update the top position only if it needs adjustment
      const topPosition = isPopupAbove ? newTopPosition : position.y - (popupRect.bottom - windowHeight);
      popupElement.style.top = `${topPosition}px`;
    }

    return () => {
      // Cleanup function to reset the top position when the component is unmounted
      popupElement.style.top = "";
    };
  }, [position]);

  return (
    <PopupContainer
      ref={popupRef}
      onMouseMove={() => {
        handleClearPopup();
      }}
      onMouseEnter={handleClearPopup}
      position={position}
    >
      <PopupContent>
        <PopupStats>
          <PopupStat>
            <ItemName>
              <InfoDetail item={item} />
            </ItemName>
          </PopupStat>
        </PopupStats>
      </PopupContent>
    </PopupContainer>
  );
};

export default InfoPopup;
