import styled, { css, keyframes } from "styled-components";
import LazyLoad from "react-lazyload";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ButtonLogin = styled.button`
  margin: 25px 0 0 0;
  width: 45%;
  margin-right: 10%;
  height: 50px;
  color: rgb(238, 226, 226);
  font-size: 18px;
  font-weight: 600;
  border: 2px solid rgba(255, 255, 255, 0.438);
  background: rgba(105, 105, 105, 0);
  margin-top: 40px;
  outline: none;
  cursor: url("/assets/mousePointer.png"), auto;
  border-radius: 8px;
`;
export const ButtonWelcome = styled.button`
  margin: 25px 0 0 0;
  width: 50%;
  height: 50px;
  color: rgb(238, 226, 226);
  font-size: 18px;
  font-weight: 600;
  border: 2px solid rgba(255, 255, 255, 0.438);
  background: rgba(105, 105, 105, 0);
  margin-top: 1px;
  outline: none;
  cursor: url("/assets/mousePointer.png"), auto;
  border-radius: 8px;
`;

export const WelcomeLabel = styled.div`
  font-weight: bold;
  font-size: 170%;
  margin-bottom: 5rem;
  text-shadow: 0.5px 5px 0px rgba(91, 165, 207, 1), -0.5px -0.5px 0px rgba(91, 165, 207, 1), 0.5px -0.5px 0px rgba(91, 165, 207, 1), -0.5px 0.5px 0px rgba(91, 165, 207, 1);
`;

export const ButtonCancel = styled.button`
  margin: 25px 0 0 0;
  width: 45%;
  height: 50px;
  color: rgb(238, 226, 226);
  font-size: 18px;
  font-weight: 600;
  border: 2px solid rgba(255, 255, 255, 0.438);
  background: rgba(105, 105, 105, 0);
  margin-top: 10px;
  outline: none;
  cursor: url("/assets/mousePointer.png"), auto;
  border-radius: 8px;
`;

export const InputLogin = styled.input`
  height: 2.5rem;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 1.525rem;
  margin-bottom: 0.5rem;
  outline: none;
  color: #000;
  border: 1px solid rgba(255, 255, 255, 0.438);
  border-radius: 8px;
  background: rgba(105, 105, 105, 0);
  cursor: url("/assets/mouseText.png"), auto;
  ::placeholder {
    color: rgb(238, 226, 226);
    opacity: 0.2;
  }
`;

export const LoginBackground = styled(LazyLoad)`
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("/assets/background/backgroundLogin.png");
  cursor: url("/assets/mouseArrow.png"), auto;
`;

export const WelcomeImage = styled.img`
  margin-top: -20%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
      animation: ${fadeIn} 0.5s ease-in-out;
    `};
`;

export const LoginCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 700px;
  height: 550px;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-shadow: -3px -3px 9px #aaa9a9a2, 3px 3px 7px rgba(147, 149, 151, 0.671);
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
  font-size: 25px;
  font-weight: 600;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  color: #000;
  user-select: none;
  cursor: url("/assets/mouseArrow.png"), auto;
`;

export const LoginHeader = styled.div`
  font-weight: bold;
  font-size: 170%;
  margin-bottom: 5rem;
  cursor: url("/assets/mouseArrow.png"), auto;
  text-shadow: 0.5px 5px 0px rgba(91, 165, 207, 1), -0.5px -0.5px 0px rgba(91, 165, 207, 1), 0.5px -0.5px 0px rgba(91, 165, 207, 1), -0.5px 0.5px 0px rgba(91, 165, 207, 1);
`;
export const RegisterButton = styled.a`
  color: black;
  margin-top: 2rem;
  font-size: 1.125rem;
  font-weight: bold;
  cursor: url("/assets/mousePointer.png"), auto;
  text-decoration: none;
  text-shadow: 0.5px 0.5px 0px gray, -0.5px -0.5px 0px gray, 0.5px -0.5px 0px gray, -0.5px 0.5px 0px gray;
`;
