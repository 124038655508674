import styled from "styled-components";
import LazyLoad from "react-lazyload";

export const ButtonRegister = styled.button`
  margin: 25px 0 0 0;
  margin-right: 10%;
  height: 50px;
  color: rgb(238, 226, 226);
  font-size: 18px;
  font-weight: 600;
  border: 2px solid rgba(255, 255, 255, 0.438);
  background: rgba(105, 105, 105, 0);
  margin-top: 40px;
  outline: none;
  cursor: url("/assets/mousePointer.png"), auto;
  border-radius: 8px;
`;

export const RegisterBackground = styled(LazyLoad)`
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("/assets/background/backgroundLogin.png");
  cursor: url("/assets/mouseArrow.png"), auto;
`;

export const ButtonCancel = styled.button`
  margin: 25px 0 0 0;
  height: 50px;
  color: rgb(238, 226, 226);
  font-size: 18px;
  font-weight: 600;
  border: 2px solid rgba(255, 255, 255, 0.438);
  background: rgba(105, 105, 105, 0);
  margin-top: 10px;
  outline: none;
  cursor: url("/assets/mousePointer.png"), auto;
  border-radius: 8px;
`;

export const InputRegister = styled.input`
  height: 2.5rem;
  width: 100%;
  font-size: 18px;
  text-align: center;
  outline: none;
  color: rgb(238, 226, 226);
  border: 1px solid rgba(255, 255, 255, 0.438);
  border-radius: 8px;
  background: rgba(105, 105, 105, 0);
  cursor: url("/assets/mouseText.png"), auto;
  ::placeholder {
    color: rgb(238, 226, 226);
    opacity: 0.5;
  }
`;

export const RegisterCard = styled.div`
  display: grid;
  flex-direction: column;
  justify-content: space-between;
  max-width: 66.5rem;
  height: 33rem;
  grid-template-columns: auto 1fr;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-shadow: -3px -3px 9px #aaa9a9a2, 3px 3px 7px rgba(147, 149, 151, 0.671);
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
  font-size: 25px;
  font-weight: 600;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  color: rgb(238, 226, 226);
  user-select: none;
  cursor: url("/assets/mouseArrow.png"), auto;
`;

export const SwiperContainer = styled.div`
  height: 18rem;
  display: flex;
  margin-top: 2rem;
  align-items: center;
  justify-content: center;
`;

export const CharacterImage = styled.img`
  margin-top: 2rem;
  border-radius: 5%;
  border: 0.3rem solid #ccc;
  box-shadow: -3px -3px 9px #aaa9a9a2, 3px 3px 7px rgba(147, 149, 151, 0.671);
  width: 15rem;
  height: 15rem;
`;

export const NavigationButton = styled.button`
  margin: 0 10px;
  border-radius: 50%;
  background: transparent;
  backdrop-filter: blur(15px);
  cursor: url("/assets/mousePointer.png"), auto;
  min-width: 2.62rem;
  min-height: 2.62rem;
  border: 0.1rem solid #ccc;
  box-shadow: -3px -3px 9px #aaa9a9a2, 3px 3px 7px rgba(147, 149, 151, 0.671);
  margin-top: 2rem;
`;

export const RegisterGridLeft = styled.div`
  padding-right: 1rem;
  padding-left: 1rem;
  justify-self: start;
  width: 31rem;
`;

export const RegisterGridRight = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  justify-self: end;
  align-items: center;
  text-align: center;
  width: 31rem;
`;

export const RegisterHeader = styled.div`
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  align-items: center;
  cursor: url("/assets/mouseArrow.png"), auto;
  color: black;
  font-weight: bold;
  font-size: 170%;
  text-shadow: 0.5px 5px 0px rgba(91, 165, 207, 1), -0.5px -0.5px 0px rgba(91, 165, 207, 1), 0.5px -0.5px 0px rgba(91, 165, 207, 1), -0.5px 0.5px 0px rgba(91, 165, 207, 1);
`;

export const LoginButton = styled.a`
  color: rgb(238, 226, 226);
  margin-top: 2rem;
  font-size: 16px;
  cursor: url("/assets/mousePointer.png"), auto;
  text-decoration: none;
`;

export const RegisterRadioButton = styled.button`
  margin-top: 2rem;
  outline: none;
  background-color: ${(props) => props.inputColor || "transparent"};
  color: white;
  font-size: 17px;
  border-radius: 15%;
  font-weight: bold;
`;
export const RegisterRaceSelection = styled.div`
  display: flex;
  justify-content: space-around;
`;
