import styled, { keyframes, css } from "styled-components";
import LazyLoad from "react-lazyload";

export const PubBackground = styled.div`
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  background-image: ${(props) => `url(${props.pic})` || null};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;
const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const shakeAnimationEnemy = keyframes`
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(20px);
  }
  40% {
    transform: translateX(-5px);
  }
  60% {
    transform: translateX(10px);
  }
  80% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
`;
const shakeAnimationPlayer = keyframes`
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-20px);
  }
  40% {
    transform: translateX(5px);
  }
  60% {
    transform: translateX(-10px);
  }
  80% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
`;

const vsAnimation = keyframes`
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
    transform: scale(7);
  }

  38% {
    animation-timing-function: ease-out;
    opacity: 1;
    transform: scale(1);
  }

  55% {
    animation-timing-function: ease-in;
    transform: scale(1.5);
  }

  72% {
    animation-timing-function: ease-out;
    transform: scale(1);
  }

  81% {
    animation-timing-function: ease-in;
    transform: scale(1.24);
  }

  89% {
    animation-timing-function: ease-out;
    transform: scale(1);
  }

  95% {
    animation-timing-function: ease-in;
    transform: scale(1.04);
  }

  100% {
    animation-timing-function: ease-out;
    transform: scale(1);
  }
`;

const fadeOutAnimation = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

export const FightContainer = styled(LazyLoad)`
  display: flex;
  position: absolute;
  background-image: ${(props) => (props.arena ? "url('/assets/background/backgroundArena.png')" : null)};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100%;
  width: 100%;
  z-index: 3;
`;

export const FightPlayerSide = styled.div`
  flex: 1;
  background-image: linear-gradient(to right, rgba(6, 66, 144, 0.3), rgba(0, 0, 0, 0));
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const FightEnemySide = styled.div`
  flex: 1;
  background-image: linear-gradient(to left, rgba(184, 8, 8, 0.3), rgba(0, 0, 0, 0));
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FightFramePlayer = styled.div`
  width: 50%;
  height: 70%;
  display: flex;
  justify-content: center;
  margin-left: ${(props) => props.margin || "0%"};
  border-radius: 2%;
  border: 0.4rem solid rgba(25, 12, 10);
  z-index: 5;
  background-color: rgba(34, 13, 9, 0.95);
  ${({ shaking }) =>
    shaking &&
    css`
      animation: ${shakeAnimationPlayer} 0.5s ease-in-out infinite;
    `}
`;

export const FightFrameEnemy = styled.div`
  width: 50%;
  height: 70%;
  display: flex;
  justify-content: center;
  margin-right: ${(props) => props.margin || "0%"};
  border: 0.4rem solid rgba(25, 12, 10);
  border-radius: 2%;
  z-index: 5;
  background-color: rgba(34, 13, 9, 0.95);
  ${({ shaking }) =>
    shaking &&
    css`
      animation: ${shakeAnimationEnemy} 0.5s ease-in-out infinite;
    `}
`;

export const FightPicture = styled(LazyLoad)`
  margin-top: 5%;
  width: 90%;
  height: 45%;
  z-index: 6;
  padding: 5;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.9);
  background-color: rgba(0, 0, 0, 0.5);
  background-image: ${(props) => `url(${props.image})` || null};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

export const FightNameLabel = styled.div`
  margin-top: 2%;
  font-size: 1.2rem;
  font-weight: bold;
  color: rgba(225, 146, 10, 0.5);
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 1);
  text-align: center;
`;
export const FightHpLabel = styled.div`
  margin-top: 1%;
  font-size: 1.1rem;
  font-weight: bold;
  color: rgba(225, 146, 255, 0.5);
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 1);
  text-align: left;
  margin-left: 0;
`;
export const FightStatLabel = styled.div`
  margin-top: 2%;
  font-size: 1.1rem;
  font-weight: bold;
  color: rgba(240, 113, 120, 0.5);
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 1);
  text-align: left;
  margin-left: 0;
`;

export const FightHpFrame = styled.div`
  height: 5%;
  width: 80%;
  margin-top: 1%;
  background-color: ${(props) => (props.widthPercent <= 0 ? "rgba(98, 20, 15, 0.5)" : "rgba(30, 29, 32, 0.7)")};
  font-weight: bold;
  color: white;
  display: flex;
  cursor: url("/assets/mouseArrow.png"), auto;
  border: 2px solid rgba(0, 0, 0, 0.5);
  z-index: 6;
  position: relative;
`;
export const FightHpFill = styled.div`
  width: ${(props) => props.widthPercent || "0"}%;
  height: 87%;
  display: flex;
  border: ${(props) => (props.widthPercent <= 1 ? null : "2px solid rgba(0, 0, 0, 0.3)")};
  background-color: rgba(0, 146, 10, 0.5);
  z-index: 7;
`;

export const FightFrameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const FightFrameLine = styled.div`
  height: 1%;
  width: 100%;
  background-color: rgba(25, 12, 10);
`;

export const FightVsText = styled(LazyLoad)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: radial-gradient(circle at center, rgba(0, 0, 0, 0.5) 0%, transparent 40%);
  z-index: 9;
  font-size: 390%;
  inset: 50%;
  width: 30%;
  height: 30%;
  color: rgba(200, 53, 8, 0.8);
  font-weight: bold;
  text-shadow: 4px 4px 0px black, -4px -4px 0px black, 4px -4px 0px black, -4px 4px 0px black;
  -webkit-user-drag: none;
  user-select: none;
  animation: ${vsAnimation} 1s ease forwards;
  margin-top: -15%;
  margin-left: -15%;
  animation: ${vsAnimation} 1s ease forwards, ${fadeOutAnimation} 1s ease 2s forwards;
`;

export const FightHpDeficitUser = styled.div`
  position: absolute;
  left: 17%;
  top: 25%;
  font-weight: bold;
  animation: ${pulse} 1s ease-in-out infinite;
  font-size: ${(props) => props.criticalSize || "400%"};
  color: ${(props) => props.critical || "white"};
  text-shadow: 4px 4px 0px black, -4px -4px 0px black, 4px -4px 0px black, -4px 4px 0px black;
  width: 10%;
  height: 10%;
  z-index: 9;
`;
export const FightHpDeficitEnemy = styled.div`
  position: absolute;
  left: 68%;
  top: 25%;
  font-weight: bold;
  font-size: ${(props) => props.criticalSize || "400%"};
  color: ${(props) => props.critical || "white"};
  animation: ${pulse} 1s ease-in-out infinite;
  text-shadow: 4px 4px 0px black, -4px -4px 0px black, 4px -4px 0px black, -4px 4px 0px black;
  width: 10%;
  height: 10%;
  z-index: 9;
`;

export const PubPackage = styled.div`
  display: flex;
  position: absolute;
  /* background-color: rgba(80, 51, 44, 0.5); */
  width: 79%;
  height: 100%;
  z-index: 1;
`;
export const PubQuestMan = styled.div`
  position: relative;
  width: 14.375rem;
  height: 23.125rem;
  top: 40%;
  left: 50%;
  filter: drop-shadow(6px 4px 2px rgba(0, 0, 0, 0.7));

  background-image: url("/assets/people/questMan1.png");
  background-size: cover;
  cursor: url("/assets/mousePointer.png"), auto;
  z-index: 3;
`;

export const BlurEnding = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1rem; /* Adjust the height as per your requirement */
  border-radius: 100%;

  backdrop-filter: blur(0.04rem); /* Apply the desired blur amount */
  -webkit-backdrop-filter: blur(0.05rem); /* For Safari support */

  /* Add any additional styles for the overlay */
`;

export const PubEnergyBar = styled.div`
  position: absolute;
  background-color: rgba(68, 46, 42, 0.6);
  border-radius: 15px 15px 15px;
  width: 50%;
  height: 5%;
  display: flex;

  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
  top: 90%;
  left: 25%;
  z-index: 3;
`;

export const PubEnergyLabel = styled.div`
  position: absolute;
  font-weight: bolder;
  color: rgba(255, 255, 255, 0.6);
  font-size: 150%;
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 1);
  left: 50%;
  transform: translate(-50%, -5%);
  z-index: 5;
`;

export const PubEnergyBarFill = styled.div`
  background-color: rgba(197, 119, 53, 0.6);
  width: ${(props) => props.inputWidth || "0%"};
  border-radius: inherit;

  height: 85%;
  z-index: 4;
`;

export const PubLoadingBar = styled.div`
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);

  position: absolute;
  background-color: rgba(68, 46, 42, 0.9);
  border-radius: 15px 15px 15px;
  width: 50%;
  height: 5%;
  display: flex;

  align-items: center;

  top: 90%;
  left: 25%;
  z-index: 3;
`;
export const PubLoadingBarFill = styled.div`
  background-color: rgba(112, 149, 147, 0.7);
  width: ${(props) => props.inputWidth || "0%"};
  border-radius: inherit;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  height: 85%;
  z-index: 4;
`;

export const PubTabButton = styled.button`
  position: relative;
  cursor: url("/assets/mousePointer.png"), auto;
  height: 80%;
  width: 8%;
  margin-left: 0.5rem;
  font-weight: bold;
  font-size: 120%;
  background-color: ${(props) => props.selected || "rgba(234, 172, 115, 0.5)"};
  border-bottom: none;
  z-index: ${(props) => (props.selected ? 6 : 1)};
  transform: ${(props) => (props.selected ? "translate(0%, 5%)" : null)};

  &:hover {
    background-color: ${(props) => props.selected || "rgba(234, 172, 115, 0.9)"};
  }
`;

export const Eurodollar = styled.span`
  color: #27b130;
  text-shadow: 0.5px 0.5px 0px black, -0.5px -0.5px 0px black, 0.5px -0.5px 0px black, -0.5px 0.5px 0px black;
`;

export const PubNameOfQuest = styled.p`
  position: relative;
  width: 100%;
  height: 30px;
  text-align: center;
  color: orange;
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 1);
  cursor: url("/assets/mouseArrow.png"), auto;
  z-index: 3;
  font-weight: bold;
  font-size: 255%;
`;

export const PubCancelRunningQuest = styled.button`
  color: #dccbbe;
  min-width: 9rem;
  /* background-image: url('/assets/copperBar.png'); */
  opacity: 0.9;
  filter: blur(0.005rem);
  background-color: ${(props) => (props.selected ? "rgba(85, 46, 40, 1)" : "rgba(117, 63, 56, 1)")};
  flex-grow: 1;
  border-radius: 5px;
  border: 2px solid #8c5d37; /* Adjust the color to match the wooden plank style */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 1); /* Optional: Add a shadow effect */
  outline: none;
  cursor: url("/assets/mousePointer.png"), auto;
  font-weight: bold;
  font-size: 20px;
  z-index: 3;
  position: absolute;
  height: 5%;
  top: 92%;
  left: 10%;
  &:hover {
    background-color: ${(props) => (props.selected ? "rgba(85, 46, 40, 1)" : "rgba(179, 113, 98, 0.9)")};
    transform: scale(1.1);
  }
`;

export const PubSkipRunningQuest = styled.button`
  color: #dccbbe;
  min-width: 9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-image: url('/assets/copperBar.png'); */
  filter: blur(0.005rem);
  background-color: ${(props) => (props.selected ? "rgba(85, 46, 40, 1)" : "rgba(117, 63, 56, 1)")};
  flex-grow: 1;
  border-radius: 5px;
  border: 2px solid #8c5d37; /* Adjust the color to match the wooden plank style */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 1); /* Optional: Add a shadow effect */
  outline: none;
  cursor: url("/assets/mousePointer.png"), auto;
  font-weight: bold;
  font-size: 20px;
  z-index: 3;
  position: absolute;
  height: 5%;
  top: 92%;
  right: 10%;
  &:disabled {
    background-color: "rgba(5, 6, 0, 1)";
  }
  &:hover {
    background-color: ${(props) => (props.selected ? "rgba(85, 46, 40, 1)" : "rgba(179, 113, 98, 1)")};
    transform: scale(1.1);
  }
`;

export const MessageContainer = styled.div`
  background-color: rgba(64, 38, 29, 1);
  height: 95%;
  border-top: 0.1rem solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  z-index: 5;
`;

export const MessageTitle = styled.p`
  font-weight: bold;
  color: orange;
  padding-left: 1rem;
  padding-top: 1rem;
  font-size: 1.5rem;
  margin: 0;
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 1);
`;

export const MessageMessage = styled.p`
  padding-top: 0.5rem;
  padding-left: 2rem;
  margin: 0;
`;

export const MessageRewardTitle = styled.p`
  padding-left: 1rem;
  font-weight: bold;
`;

export const MessageXp = styled.p`
  padding-top: 0.5rem;
  padding-left: 5rem;
  font-weight: bold;
  margin: 0;
`;

export const MessageMoney = styled.p`
  padding-top: 0.5rem;
  padding-left: 5rem;
  margin: 0;
  font-weight: bold;
`;

export const MessageTime = styled.p`
  padding-top: 0.5rem;
  padding-left: 5rem;
  margin: 0;
`;

export const MessageBottom = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  width: 100%;
  height: 18%;
`;

export const MessageSelectButton = styled.button`
  width: 20%;
  height: 55%;
  font-weight: bold;
  font-size: 2vw;
  background-color: rgba(20, 70, 59, 0.5);
  color: #dccbbe;
  opacity: 0.9;
  filter: blur(0.005rem);
  background-color: ${(props) => (props.selected ? "rgba(85, 46, 40, 1)" : "rgba(117, 63, 56, 1)")};
  border-radius: 5px;
  border: 2px solid #8c5d37; /* Adjust the color to match the wooden plank style */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 1); /* Optional: Add a shadow effect */
  outline: none;
  cursor: url("/assets/mousePointer.png"), auto;
  font-weight: bold;
  font-size: 20px;
  &:hover {
    background-color: ${(props) => (props.selected ? "rgba(85, 46, 40, 1)" : "rgba(179, 113, 98, 0.9)")};
    transform: scale(1.1);
    letter-spacing: 0.1rem;
  }
`;

export const QuestWindow = styled.div`
  background-color: rgba(103, 69, 44, 0.95);
  width: 55%;
  height: 60%;
  top: 50%;
  left: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 1%;
  transform: translate(15%, 30%);
  z-index: 4;
`;
export const QuestWindowHeader = styled(LazyLoad)`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  background-image: url("/assets/background/woodenBackground.png");
  height: 5%;
`;
export const CloseButton = styled.button`
  outline: none;
  background-color: rgba(250, 0, 0, 0);
  cursor: url("/assets/mousePointer.png"), auto;
  border: none;
  font-weight: bold;
  font-size: 120%;
  color: rgb(136 35 0);
  width: 7%;
`;
export const QuestWindowBackground = styled(LazyLoad)`
  height: 100%;
  border-radius: inherit;
  background-image: url("/assets/background/woodenBackground.png");
  width: 100%;
  color: lightgray;
  display: flex;
  flex-direction: column;
`;

export const QuestTabBackground = styled.div`
  display: flex;
  height: 10%;
  justify-content: space-between;
  align-items: end;
  widows: 100%;
`;

export const PubTimeBar = styled.p`
  position: absolute;
  font-weight: bolder;
  color: white;
  font-size: 170%;
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 1);
  top: 94%;
  left: 50%;
  transform: translate(-50%, -150%);
  z-index: 5;
`;

export const FightStatTable = styled.table`
  float: right;
  color: white;
  width: 90%;
  font-size: 1.7vh;
  margin-top: 1%;
  margin-bottom: 5%;
`;

export const FightStatColumn = styled.td`
  padding: 0.5vh;
  border: 1px solid black;
  background-color: rgba(0, 0, 0, 0.3);
  font-size: 1.1rem;
  font-weight: bold;
  color: rgba(240, 113, 120, 0.5);
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 1);
  text-align: left;
  margin-left: 0;
`;
