const TrinketShopScreen = () => {
  return (
    <center>
      <h1>TrinketShopScreen</h1>
      <img loading="lazy" width={"25%"} draggable={false} alt="altWorkInProgress" src={"/assets/workInProgress.png"} />
    </center>
  );
};

export default TrinketShopScreen;
