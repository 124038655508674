import { useState } from "react";
import { ArmorShopBottomContainer, ArmorShopBox, ArmorShopContainer, ArmorShopDraggableBox, ArmorShopItem, ArmorShopItemsContainer, ArmorShopSellerBox, ArmorShopSellerContainer, ArmorShopTopContainer, InnerContainer } from "../../../styles/StyledArmorShop";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { InventoryBox, ProfileInventoryBackground } from "../../../styles/StyledProfile";
import TooltipPopup from "../../PopupsVersions/TooltipPopup";
import InfoPopup from "../../PopupsVersions/InfoPopup";
import { useEffect } from "react";
import { onDragEnd } from "./components/Inventory/InventoryFunctions";

const ArmorShopOneItem = ({ props, stateInventory, handleItemLeave, handleItemHover, handleMouseMove, armorIndex, armorItem }) => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "50%" }}>
      <Droppable droppableId={`shop_slot_${armorIndex.toString()}`} onMouseLeave={handleItemLeave} isDropDisabled={true}>
        {(provided, snapshot) => {
          return (
            <ArmorShopBox {...provided.droppableProps} ref={provided.innerRef} onMouseEnter={() => handleItemHover(armorItem)} onMouseLeave={handleItemLeave} onMouseMove={handleMouseMove}>
              <Draggable style={{ cursor: "none" }} draggableId={`${armorItem.type}_${armorIndex}shop`} index={armorIndex} onMouseLeave={handleItemLeave} isDragDisabled={Number(props.user.eurodollar) < Number(armorItem.price)}>
                {(provided, snapshot) => {
                  return (
                    <ArmorShopDraggableBox ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} isDragging={snapshot.isDragging} onMouseLeave={handleItemLeave}>
                      <ArmorShopItem loading="lazy" draggable={false} alt="altContainerLeft" src={armorItem.image} isDragging={snapshot.isDragging} onMouseLeave={handleItemLeave} />
                    </ArmorShopDraggableBox>
                  );
                }}
              </Draggable>
              {provided.placeholder}
            </ArmorShopBox>
          );
        }}
      </Droppable>
    </div>
  );
};

const ArmorShopSeller = ({ props, handleItemLeave, handleMouseMove }) => {
  return (
    <>
      <Droppable droppableId={`seller_slot`} onMouseLeave={handleItemLeave}>
        {(provided, snapshot) => {
          return (
            <ArmorShopSellerBox {...provided.droppableProps} ref={provided.innerRef} onMouseLeave={handleItemLeave} onMouseMove={handleMouseMove}>
              {"SELL ITEM HERE"}
            </ArmorShopSellerBox>
          );
        }}
      </Droppable>
    </>
  );
};

const ArmorShopItems = ({ props, stateInventory, handleItemLeave, handleItemHover, handleMouseMove }) => {
  const armors = props.user.shop.armors;
  return (
    <ArmorShopItemsContainer onMouseEnter={handleItemLeave} onMouseLeave={handleItemLeave}>
      <InnerContainer>
        <ArmorShopOneItem props={props} armorIndex={0} armorItem={armors[0]} handleItemHover={handleItemHover} handleMouseMove={handleMouseMove} handleItemLeave={handleItemLeave} />
        <ArmorShopOneItem props={props} armorIndex={1} armorItem={armors[1]} handleItemHover={handleItemHover} handleMouseMove={handleMouseMove} handleItemLeave={handleItemLeave} />
      </InnerContainer>
      <InnerContainer>
        <ArmorShopOneItem props={props} armorIndex={2} armorItem={armors[2]} handleItemHover={handleItemHover} handleMouseMove={handleMouseMove} handleItemLeave={handleItemLeave} />
        <ArmorShopOneItem props={props} armorIndex={3} armorItem={armors[3]} handleItemHover={handleItemHover} handleMouseMove={handleMouseMove} handleItemLeave={handleItemLeave} />
      </InnerContainer>
    </ArmorShopItemsContainer>
  );
};

const Inventory = ({ props, stateInventory, handleItemLeave, handleItemHover, handleMouseMove }) => {
  return (
    <ProfileInventoryBackground onMouseLeave={handleItemLeave}>
      {stateInventory.map((item, index) => (
        <Droppable droppableId={`inventory_slot_${index.toString()}`} key={index} onMouseLeave={handleItemLeave} isDropDisabled={item?.empty === false}>
          {(provided, snapshot) => {
            return (
              <InventoryBox {...provided.droppableProps} ref={provided.innerRef} onMouseEnter={() => handleItemHover(item)} onMouseLeave={handleItemLeave} onMouseMove={handleMouseMove}>
                {item.empty === false && (
                  <Draggable draggableId={`${item.type}_${index}`} index={index} onMouseLeave={handleItemLeave}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            userSelect: "none",
                            display: "flex",
                            ...provided.draggableProps.style,
                          }}
                          onMouseLeave={handleItemLeave}
                        >
                          <img loading="lazy" draggable={false} alt="altInventoryBox" src={item.image} style={{ maxWidth: "100%", height: "auto" }} onMouseLeave={handleItemLeave} />
                        </div>
                      );
                    }}
                  </Draggable>
                )}
                {provided.placeholder}
              </InventoryBox>
            );
          }}
        </Droppable>
      ))}
    </ProfileInventoryBackground>
  );
};

const ArmorShopScreen = ({ props, setCursorIsHidden }) => {
  const [stateInventory, setStateInventory] = useState(props.user.inventory);
  const [stateSlots, setStateSlots] = useState(props.user.slots);
  const [popupItem, setPopupItem] = useState(null);
  const [popupInfo, setPopupInfo] = useState(null);
  const [interactedItem, setInteractedItem] = useState(null);
  const [interaction, setInteraction] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const handleMouseMove = (event) => {
    setMousePosition({ x: event.clientX, y: event.clientY });
  };
  const handleItemHover = (item) => {
    setPopupItem(item);
  };
  const handleItemLeave = () => {
    setPopupItem(null);
  };
  const handleClearPopup = () => {
    setPopupItem(null);
  };
  const handleInfoHover = (item) => {
    setPopupInfo(item);
  };
  const handleInfoLeave = () => {
    setPopupInfo(null);
  };

  //EVERYTIME IF SOMETHING WAS MOVED
  useEffect(() => {
    if (stateInventory !== props.user.inventory) {
      props.setLoading(true, "all");
      console.log("[info] FETCH ZMENU!!!, ", interactedItem);
      if (interactedItem?.type) {
        console.log("[info] neco kupil, nebo prodal");
        if (interaction === "buy") {
          props.goUpdateInvAndShop(interactedItem, interaction, stateInventory);
        } else if (interaction === "sell") {
          props.handleSellingItem(interactedItem.varId);
        } else {
          console.log("[error] no interaction status");
        }
      } else {
        console.log("[info] nic nekupil");
        props.goUpdateInvAndSlots(stateSlots, stateInventory);
      }
      setInteractedItem(null);
      setInteraction(null);
    }
  }, [stateInventory]);

  useEffect(() => {
    setStateInventory(props.user.inventory);
  }, [props.user.inventory]);

  const updateSlotByName = (name, newItem) => {
    const index = stateSlots.findIndex((item) => item.type === name);
    if (index === -1) {
      // Item not found, do nothing
      return;
    }
    setStateSlots(
      stateSlots.map((item, i) => {
        if (i === index) {
          return newItem;
        } else {
          return item;
        }
      })
    );
  };
  const updateInventoryById = (name, newItem) => {
    const index = stateInventory.findIndex((item) => item.varId === name);
    console.log("new item: ", newItem);
    // setInteraction("buy");
    // setInteractedItem(newItem);
    if (index === -1) {
      // Item not found, do nothing
      console.log("[error] item not found");
      return;
    }
    setStateInventory(
      stateInventory.map((item, i) => {
        if (i === index) {
          return newItem;
        } else {
          return item;
        }
      })
    );
  };

  return (
    <ArmorShopContainer onMouseMove={handleMouseMove}>
      <DragDropContext
        onDragStart={() => setCursorIsHidden(true)}
        onDragEnd={(result) => {
          onDragEnd(result, stateSlots, setStateSlots, stateInventory, setStateInventory, updateSlotByName, updateInventoryById, props.user.shop.armors, setInteractedItem, setInteraction, props.handleSellingItem);
          setCursorIsHidden(false);
        }}
      >
        <ArmorShopTopContainer onMouseMove={handleMouseMove}>
          <ArmorShopItems handleMouseMove={handleMouseMove} handleItemHover={handleItemHover} handleItemLeave={handleItemLeave} stateInventory={stateInventory} props={props} />
          <ArmorShopSellerContainer>
            <ArmorShopSeller props={props} handleItemLeave={handleItemLeave} handleMouseMove={handleMouseMove} />
          </ArmorShopSellerContainer>
        </ArmorShopTopContainer>
        <ArmorShopBottomContainer onMouseMove={handleMouseMove}>
          <Inventory handleMouseMove={handleMouseMove} handleItemHover={handleItemHover} handleItemLeave={handleItemLeave} stateInventory={stateInventory} props={props} />
        </ArmorShopBottomContainer>
      </DragDropContext>
      {popupItem && <TooltipPopup props={props} handleClearPopup={handleClearPopup} onMouseEnter={handleItemLeave} item={popupItem} position={mousePosition} onMouseLeave={handleItemLeave} />}
      {popupInfo && <InfoPopup item={popupInfo} position={mousePosition} handleClearPopup={handleInfoLeave} onMouseLeave={handleInfoLeave} />}
    </ArmorShopContainer>
  );
};

export default ArmorShopScreen;
