import { POST_HELLO } from "../types";

const initialState = {
  hello: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case POST_HELLO:
      return {
        ...state,
        hello: action.payload,
      };
    default:
      return state;
  }
}
