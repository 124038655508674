import styled, { keyframes, css } from "styled-components";
import LazyLoad from "react-lazyload";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const buttonAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(1.2);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const flickerAnimation = keyframes`
  0%,
  18%,
  22%,
  25%,
  53%,
  57%,
  100% {
    text-shadow: 0 0 40px #0fa, 0 0 110px #0fa, 0 0 190px #0fa, 0 0 400px #0fa,
      0 0 500px #0fa, 0 0 600px #0fa, 0 0 700px #0fa, 0 0 100px #0fa;
  }

  20%,
  24%,
  55% {
    text-shadow: none;
  }
`;

export const RotatingImage = styled.img`
  animation: ${rotate360} 3s linear infinite;
  position: absolute;
  translate: -50% -50%;
  user-select: none;
`;

export const WorkInProgress = styled.img`
  animation: ${flickerAnimation} 1.5s infinite alternate;
`;

export const FullScreen = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  cursor: ${(props) => (props.cursorIsHidden ? "none" : 'url("/assets/mouseArrow.png"), auto')};
`;
export const LeftPanel = styled(LazyLoad)`
  display: flex;
  flex-direction: column;
  width: 20%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9);
  background-color: rgba(224, 159, 93, 0.5);
  background-image: url("/assets/copperBar.png");
  min-width: 180px;
  padding: 10px;
  border: 2px solid rgba(0, 0, 0, 0.5);
  user-select: none;
`;
export const MainScreen = styled.div`
  width: 80%;
  background-color: ${(props) => (props.blur ? "rgba(241, 194, 170, 0.6)" : null)};
  user-select: none;
`;

export const QuestTimeLabelStyle = styled.a`
  color: orange;
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 1);
`;

export const WelcomeLabel = styled.a`
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
      animation: ${fadeIn} 0.5s ease-in-out;
    `};

  margin-top: 20rem;
  color: orange;
  font-size: 7vh;
  border: 2px rgba(132, 77, 53, 0.5);
  font-weight: bold;
  text-shadow: 0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.8);
`;

export const WelcomeDiv = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: center;
`;

export const LeftPanelButton = styled.button`
  width: 100%;
  margin-top: 10px;
  color: #dccbbe;
  min-width: 180px;
  min-height: 25px;
  opacity: 0.9;
  filter: blur(0.005rem);
  background-color: ${(props) => (props.selected ? "rgba(85, 46, 40, 1)" : "rgba(117, 63, 56, 1)")};
  flex-grow: 1;
  border-radius: 5px;
  border: 2px solid #8c5d37; /* Adjust the color to match the wooden plank style */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 1); /* Optional: Add a shadow effect */
  outline: none;
  cursor: url("/assets/mousePointer.png"), auto;
  font-weight: bold;
  font-size: 20px;

  animation: ${buttonAnimation} 1s linear 0s 1 normal;

  &:hover {
    background-color: ${(props) => (props.selected ? "rgba(85, 46, 40, 1)" : "rgba(179, 113, 98, 0.9)")};
    transform: scale(1.04);
    letter-spacing: 0.1rem;
  }
`;

export const GameLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(38, 38, 38, 0.6);
  height: 100vh;
  cursor: url("/assets/mouseArrow.png"), auto;
`;

export const GameLoadingText = styled.p`
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
`;

export const PackOfProfile = styled(LazyLoad)`
  display: flex;
  color: rgba(233, 212, 196, 0.8);
  background-color: rgba(89, 46, 42, 0.5);
  width: 100%;
  margin-top: 10px;
  text-shadow: 1px 1px 2px black, -1px -1px 2px black;
  background-image: url("/assets/background/woodenBackground.png");
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 1); /* Optional: Add a shadow effect */
  min-width: 180px;
  min-height: 60px;
  border: 2px solid rgba(0, 0, 0, 0.3);
`;

export const PackOfMiniButtons = styled(LazyLoad)`
  background-color: rgba(101, 105, 134, 0.5);
  display: flex;
  justify-content: space-evenly;
  background-image: url("/assets/background/woodenBackground.png");
  align-items: center;
  width: 100%;
  margin-top: 10px;
  min-width: 180px;
  min-height: 25px;
  flex-grow: 1;
  font-weight: bold;
  font-size: 20px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8);
  border: 2px solid rgba(0, 0, 0, 0.2);
`;

export const MiniButton = styled.button`
  height: 80%;
  background-color: ${(props) => (props.selected ? "rgba(104, 108, 109, 0.9)" : "rgba(184, 158, 159, 0.5)")};
  border-color: rgba(184, 158, 159, 0.5);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  cursor: url("/assets/mousePointer.png"), auto;
  border-radius: 15%;
  text-shadow: rgb(0, 0, 0) 3px 3px 3px;
  font-size: 20px;
  &:hover {
    background-color: ${(props) => (props.selected ? "rgba(104, 108, 109, 0.9)" : "rgba(184, 158, 159, 0.9)")};
    transform: scale(1.2);
  }
`;

export const ProfileRightPanel = styled.div`
  margin: 1vh;
  margin-left: 0;
  width: 100%;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 1); /* Optional: Add a shadow effect */
  background-color: rgb(60 34 23 / 64%);
  padding-left: 1vh;
`;

export const PackOfProfilePic = styled.div`
  max-width: 7rem;
  max-height: 7rem;
  background-color: orange;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.8); /* Optional: Add a shadow effect */
  margin: 0.5rem;
  position: relative;
  border: 2px solid rgba(0, 0, 0, 0.5);
  cursor: url("/assets/mousePointer.png"), auto;

  &:hover {
    background-color: white;
  }
`;

export const ProfileProgressBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  background-color: rgba(181, 119, 100, 0.7);
  font-weight: bold;
  height: 18%;
  color: white;
  cursor: url("/assets/mouseArrow.png"), auto;
  border: 2px solid rgba(0, 0, 0, 0.5);
`;

export const ProfileProgressBarXP = styled.div`
  background-color: rgba(0, 255, 93, 0.5);
  width: ${(props) => props.widthPercent || "1"}%;
  height: 80%;
  border: 2px solid rgba(0, 0, 0, 0.3);
`;

export const ProfileLevel = styled.a`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 1.5vh;
  text-align: center;
  font-weight: bold;
  text-shadow: 0.5px 0.5px 0px gray, -0.5px -0.5px 0px gray, 0.5px -0.5px 0px gray, -0.5px 0.5px 0px gray;
  color: black;
  cursor: url("/assets/mouseArrow.png"), auto;
  /* padding-bottom: 0.1rem; */
`;

export const ProfileLabel = styled.div`
  margin-top: 0.2vh;
  font-size: 1.5vh;
  cursor: url("/assets/mouseArrow.png"), auto;
`;
export const ProfileLabelName = styled.div`
  margin-top: 0.2vh;
  margin-right: 2vh;
  padding-left: 0.5vh;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 4px 8px;
  font-size: 1.5vh;
  cursor: url("/assets/mouseArrow.png"), auto;
  letter-spacing: 0.2vh;
  background-color: rgba(59, 36, 28, 0.7);
`;
export const ProfileEurodollar = styled.span`
  width: 1.5rem;
  color: #27b130;
  text-shadow: 0.5px 0.5px 0px black, -0.5px -0.5px 0px black, 0.5px -0.5px 0px black, -0.5px 0.5px 0px black;
`;
