import styled, { keyframes } from "styled-components";
import LazyLoad from "react-lazyload";

const glowAnimation = keyframes`
  0% {
    box-shadow: inset 0 0 0 0 rgba(255, 113, 0, 0);
  }
  100% {
    box-shadow: inset 0 0 10px 5px rgba(255, 113, 0, 0.4);
  }
`;

export const ProfileBackground = styled(LazyLoad)`
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: ${(props) => `url(${props.pic})` || null};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: -1;
`;

export const ProfilePackage = styled.div`
  position: absolute;
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 78.9%; //because left panel is 30% and border of it is like the rest
`;

export const ProfileFilter = styled.div`
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(90, 65, 54, 0.3);
`;

export const ImageProfileBorder = styled(LazyLoad)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("/assets/background/woodenBackground.png");
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 1); /* Optional: Add a shadow effect */
  background-color: rgba(39, 23, 16, 0.6);
  width: 30%;
  height: 94%;
  border: 2px solid rgba(132, 77, 53, 0.5);
  border-radius: 5%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
`;

export const ProfileInventoryBackground = styled.div`
  background-color: rgba(66, 40, 30, 0.9);
  width: 60%;
  display: flex;
  flex-direction: row;
  border: 2px solid rgba(132, 77, 53, 0.5);
  border-radius: 1%;
  height: 80%;
`;

export const InventoryBox = styled.div`
  width: 4rem;
  height: 4rem;
  background-color: rgba(0, 0, 0, 0.7);
  border: 2px solid rgba(132, 77, 53, 0.2);
  border-radius: 1%;
  display: flex;
  justify-content: center;
  /* cursor: url("/assets/mouseArrow.png"), auto; */
  align-items: center;
  margin: 2px;
  transition: box-shadow 0.5s;

  &:hover {
    animation: ${glowAnimation} 1s infinite alternate;
  }
`;

export const ProfileButtonPlus = styled.button`
  width: 100%;
  min-width: 1rem;
  height: 3vh;
  background-color: ${(props) => (props.disabled ? "black" : "rgba(171, 86, 81, 0.5)")};
  flex-grow: 1;
  border-color: rgba(171, 86, 81, 0.5);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  outline: none;
  border-radius: 3px;
  cursor: url("/assets/mousePointer.png"), auto;
  font-weight: bold;
  color: ${(props) => (props.disabled ? "darkred" : " rgba(204, 118, 47, 0.8)")};
  font-size: 2vh;

  &:hover {
    background-color: ${(props) => (props.disabled ? "black" : "rgba(171, 86, 81, 0.9)")};
    transform: scale(1.2);
  }
`;
export const ProfileStatisticsContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  font-weight: bold;
  color: white;
  flex: 1;
  display: flex;
  justify-content: space-evenly; //How boxes are ordered.
  align-items: center;
`;

export const ProfileArmorBox = styled.div`
  background-color: ${(props) => (props.isDraggingOver ? "rgba(0, 0, 0, 0.7)" : "rgba(0, 0, 0, 0.5)")};

  width: 100%;
  margin-top: 2.5%;
  height: 100%;
  transition: box-shadow 0.5s;

  &:hover {
    animation: ${glowAnimation} 1s infinite alternate;
  }
`;
export const ProfileLeftArmorContainer = styled.div`
  background-color: rgba(66, 40, 30, 0.5);
  margin-top: 1rem;
  width: 5%;
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 3%;
  border: 2px solid rgba(132, 77, 53, 0.5);
  border-radius: 1%;
  padding: 0.2rem;
`;
export const ProfileRightArmorContainer = styled.div`
  background-color: rgba(66, 40, 30, 0.5);
  margin-top: 1rem;
  width: 5%;
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 3%;
  border: 2px solid rgba(132, 77, 53, 0.5);
  border-radius: 1%;
  padding: 0.2rem;
`;
export const ProfileMainContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  font-weight: bold;
  color: white;
  flex: 1;
  display: flex;
  justify-content: space-evenly; //How boxes are ordered.
  align-items: center;
`;

export const ProfileStyledColumnMain = styled.td`
  padding: 1vh;
  color: ${(props) => (props?.orange ? "rgba(255, 116, 0, 0.8)" : null)};
  border: 1px solid black;
  background-color: rgba(137, 77, 53, 0.2);
  backdrop-filter: blur(0.1rem);
  font-weight: bold;
  -webkit-backdrop-filter: blur(0.1rem);
`;

export const ProfileStyledColumn = styled.td`
  padding: 1vh;
  color: ${(props) => (props?.orange ? "rgba(255, 116, 0, 0.8)" : null)};
  border: 1px solid black;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(0.1rem);
  -webkit-backdrop-filter: blur(0.1rem);
`;

export const ProfileTable = styled.table`
  float: right;
  font-size: 1.7vh;
  margin-top: 2%;
`;

export const ProfileArmorLabel = styled.div`
  display: flex;
  height: 100%;
  align-items: end;
  margin-left: 1%;
  color: #ae9a96;
`;
