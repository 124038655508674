import { GET_LOGIN, RESET_FAIL, SET_LOADING, SET_DISABLED_LOGIN_BUTTON, SET_ALLOWED_LOGIN_BUTTON } from "../login.types";

const initialState = {
  user: {},
  incorrect: false,
  errorMessage: "",
  loading: false,
  loginButtonDisabled: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_LOGIN:
      if (action.payload.error === true) {
        console.log("[info] error: ", action.payload.message);
        const errorMessage = action.payload.message.replace(").", "");
        const realErrorMessage = errorMessage.replace("Firebase: Error (auth/", "");

        return {
          ...state,
          user: {},
          incorrect: true,
          errorMessage: realErrorMessage,
        };
      } else {
        console.log("[info] case : ", action.type);
        console.log("[payload] ", action.payload);
        return {
          ...state,
          user: action.payload,
          incorrect: false,
          errorMessage: "",
        };
      }
    case SET_ALLOWED_LOGIN_BUTTON:
      return {
        ...state,
        loginButtonDisabled: false,
      };
    case SET_DISABLED_LOGIN_BUTTON:
      return {
        ...state,
        loginButtonDisabled: true,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case RESET_FAIL:
      return {
        ...state,
        incorrect: false,
        errorMessage: "",
      };
    default:
      return state;
  }
}
