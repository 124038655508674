import styled, { keyframes } from "styled-components";

const glowAnimationBox = keyframes`
  0% {
    box-shadow: inset 0 0 0 0 rgba(255, 113, 0, 0);
  }
  100% {
    box-shadow: inset 0 0 10px 5px rgba(255, 113, 0, 0.4);
  }
`;
const glowAnimationItem = keyframes`
  0% {
    filter: drop-shadow(6px 4px 2px rgba(255, 113, 0, 0.0));
  }
  100% {
    filter: drop-shadow(0px 0px 10px rgba(255, 113, 0, 1));
  }
`;

export const ArmorShopSellerBox = styled.div`
  display: flex;
  background-color: rgba(255, 0, 0, 0.1);
  height: 100%;
  align-items: center;
  color: gold;
  justify-content: center;
  width: 100%;
`;

export const ArmorShopBox = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  height: 50%;
  width: 50%;
  transition: box-shadow 0.5s;
  box-shadow: rgba(0, 0, 0, 0.9) 2px 2px 15px;
  border-radius: 5%;
  border: 2px solid rgba(30, 32, 34, 0.5);
  background-image: url("/assets/background/backgroundStone.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &:hover {
    animation: ${glowAnimationBox} 1s infinite alternate;
  }
`;

export const ArmorShopDraggableBox = styled.div`
  user-select: none;
  border-radius: 50% 50%;

  ${(props) =>
    props.isDragging &&
    `
    background-color: rgba(0, 0, 0, 0.1);
  `}

  &:hover {
    animation: ${glowAnimationItem} 1s infinite alternate;
  }
`;

export const ArmorShopItem = styled.img`
  max-width: 100%;
  height: auto;
`;

export const ArmorShopContainer = styled.div`
  display: flex;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("/assets/background/backgroundArmorShop.png");
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  height: 100vh;
  width: 78.9%;
  max-width: 100%;
  flex-direction: column;
`;

export const ArmorShopTopContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  font-weight: bold;
  color: white;
  flex: 1;
  display: flex;
  justify-content: space-between; //How boxes are ordered.
  align-items: center;
`;

export const ArmorShopBottomContainer = styled.div`
  background-color: #3e1d0fed;
  font-weight: bold;
  color: white;
  flex: 1;
  display: flex;
  justify-content: space-evenly; //How boxes are ordered.
  align-items: center;
  position: relative;

  &::before,
  &::after {
    content: "";
    pointer-events: none;
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    border: 2px solid #c37e0170;
  }

  &::after {
    /* z-index: 2; */
    user-select: none;
    border-width: 2px;
    margin: 6px;
  }
`;

export const ArmorShopItemsContainer = styled.div`
  background-color: rgba(66, 40, 30, 0.5);
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.9) 2px 2px 15px;
  background-image: url("/assets/background/woodenBackground.png");
  margin-left: 10%;
  border: 2px solid rgba(132, 77, 53, 0.5);
  border-radius: 1%;
  height: 70%;
  width: 23%;
`;

export const ArmorShopSellerContainer = styled.div`
  background-color: rgb(97 62 38 / 30%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 90%;
  width: 30%;
`;

export const InnerContainer = styled.div`
  display: flex;
  height: 45%;
  flex: 1;
`;
