import { GET_FETCH, RESET_FAIL, SET_FIGHTLOG, SET_STATS_LOADING, SET_LEADERBOARD, SET_LEADERBOARD_BUTTON_DOWN, SET_LEADERBOARD_SELECTED_USER, CLEAN_LEADERBOARD_SELECTED_USER, SET_QUEST_CANCELED, SET_QUEST_ALLOWED, SET_QUEST_SKIPPED, SET_QUEST_UNSKIPPED, SET_LOADINGBUTTON_DISABLED, SET_LOADINGBUTTON_ALLOWED, SET_LOADING, SET_FIGHTLOG_ARENA } from "../game.types";
import axios from "axios";
import apiUrl from "../../../../constants/";
import { toast } from "react-toastify";
import moment from "moment";

export function doResetFail() {
  return async (dispatch, getState) => {
    dispatch({
      type: RESET_FAIL,
      payload: "",
    });
  };
}

export function setLoading(status, where) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_LOADING,
      payload: { status, where },
    });
  };
}

export function selectQuest(quest, key, username, specialKey) {
  return async (dispatch, getState) => {
    console.log("[info] selectQuest()");
    try {
      const noUnixnow = moment.utc().format();
      const now = moment.utc(noUnixnow).unix();
      console.log("[info] starting with now time: ", now);
      await axios
        .post(apiUrl + "/api/quest/select", null, {
          params: {
            func: "select_quest",
            username,
            key,
            quest,
            now,
          },
        })
        .then((response) => {
          console.log("[info] response: ", response.data);
          if (response.data.error) {
            toast.error(response.data.message);
          }
          dispatch({
            type: SET_QUEST_ALLOWED,
          });
          dispatch(doFetch(username, key));
        });
    } catch (e) {
      console.log("[error] ", e);
    }
  };
}

export function setLoadingButton(disabled) {
  return async (dispatch, getState) => {
    if (disabled) {
      dispatch({
        type: SET_LOADINGBUTTON_DISABLED,
      });
    } else {
      dispatch({
        type: SET_LOADINGBUTTON_ALLOWED,
      });
    }
  };
}

export function handleSellingItem(key, username, itemVarId) {
  return async (dispatch, getState) => {
    dispatch(setLoading(true, "all"));
    try {
      await axios.post(apiUrl + "/api/user/change", { func: "sell_item", username, key, itemVarId }).then((response) => {
        console.log("[info] response: ", response.data);
        if (response.data.error) {
          toast.error(response.data.message);
        }
        if (response.data.message === "Not enought moeny") {
          toast.error("You don't have enough eurodollars");
        }
        dispatch(doFetch(username, key));
      });
    } catch (e) {
      console.log("[error] ", e);
    }
  };
}

export function skipRunningQuest(key, username) {
  return async (dispatch, getState) => {
    console.log("[info] skipRunningQuest()");
    dispatch(setLoadingButton(true));
    try {
      await axios
        .post(apiUrl + "/api/quest/skip", null, {
          params: {
            func: "skip_quest",
            username,
            key,
          },
        })
        .then((response) => {
          console.log("[info] response: ", response.data);
          if (response.data.error) {
            toast.error(response.data.message);
          }
          if (response.data.skipped === true) {
            dispatch({
              type: SET_QUEST_SKIPPED,
            });
          }
        });
    } catch (e) {
      console.log("[error] ", e);
    }
  };
}

export function cancelRunningQuest(key, username) {
  return async (dispatch, getState) => {
    console.log("[info] cancelRunningQuest()");
    dispatch(setLoadingButton(true));
    try {
      await axios
        .post(apiUrl + "/api/quest/cancel", null, {
          params: {
            func: "cancel_quest",
            username,
            key,
          },
        })
        .then((response) => {
          console.log("[info] response: ", response.data);
          if (response.data.error) {
            toast.error(response.data.message);
          }
          if (response.data.code === "1030") {
            dispatch({
              type: SET_QUEST_CANCELED,
            });
          }
          dispatch(doFetch(username, key));
        });
    } catch (e) {
      console.log("[error] ", e);
    }
  };
}

export function buyStat(key, username, stat, price) {
  return async (dispatch, getState) => {
    console.log("[info] buyStat()");
    try {
      dispatch(setStatsLoading(true));
      await axios.post(apiUrl + "/api/user/change", { func: "change_stat", username, key, stat, price }).then((response) => {
        console.log("[info] response: ", response.data);
        if (response.data.error) {
          toast.error(response.data.message);
        }
        if (response.data.message === "Not enought moeny") {
          toast.error("You don't have enough eurodollars");
        }
        dispatch(doFetch(username, key));
      });
    } catch (e) {
      console.log("[error] ", e);
    }
  };
}

export function finishQuest(specialKey, username) {
  return async (dispatch, getState) => {
    console.log("[info] finishQuest()");
    try {
      await axios
        .post(apiUrl + "/api/quest/select", null, {
          params: {
            func: "finish_quest",
            username,
            key: specialKey,
          },
        })
        .then((response) => {
          console.log("[info] response: ", response.data);
          if (response.data.error) {
            toast.error(response.data.message);
          }
          dispatch({ type: SET_QUEST_UNSKIPPED });
          if (response.data?.money > 0) {
            toast.info(`Received +${response.data.money}€$`);
            toast.info(`Received +${response.data.xp}xp`);
            if (response.data.wasLevelUp === true) {
              const levelUpSound = new Audio("/assets/audio/levelUp.mp3");
              levelUpSound.volume = 0.08;
              levelUpSound.play();
              toast.success(`LEVEL UP!!! Congratulations!!`);
            }
          }
          dispatch(doFetch(username, specialKey));
        });
    } catch (e) {
      console.log("[error] ", e);
    }
  };
}

export function fetchInvShop(specialKey, username, interactedItem, interaction, inventory) {
  return async (dispatch, getState) => {
    console.log("[info] fetchInvShop() interactedItem: ", interactedItem);
    try {
      const inventoryJSON = JSON.stringify(inventory);
      const interactedItemJSON = JSON.stringify(interactedItem);
      await axios.post(apiUrl + "/api/user/change", { func: "fetch_inv_shop", username, key: specialKey, interactedItemJSON, interaction, inventoryJSON }).then((response) => {
        console.log("[info] response: ", response.data);
        if (response.data.error) {
          toast.error(response.data.message);
        }
        dispatch(doFetch(username, specialKey));
      });
    } catch (e) {
      console.log("[error] ", e);
    }
  };
}

export function fetchInvSlots(specialKey, username, slots, inventory) {
  return async (dispatch, getState) => {
    console.log("[info] fetchInvSlots()");
    try {
      const slotsJSON = JSON.stringify(slots);
      const inventoryJSON = JSON.stringify(inventory);

      await axios.post(apiUrl + "/api/user/change", { func: "fetch_inv_slots", username, key: specialKey, slotsJSON, inventoryJSON }).then((response) => {
        console.log("[info] response: ", response.data);
        if (response.data.error) {
          toast.error(response.data.message);
        }
        dispatch(doFetch(username, specialKey));
      });
    } catch (e) {
      console.log("[error] ", e);
    }
  };
}

export function cleanLeaderboardSelectedUser() {
  return async (dispatch, getState) => {
    dispatch({
      type: CLEAN_LEADERBOARD_SELECTED_USER,
    });
  };
}

export function getLeaderboardSelectedUser(username) {
  return async (dispatch, getState) => {
    console.log("[info] getLeaderboardSelectedUser()");
    dispatch(setLoading(true, "all"));
    try {
      await axios
        .post(apiUrl + "/api/leaderboard/getuser", null, {
          params: {
            func: "get_user",
            username: username,
          },
        })
        .then((response) => {
          console.log("[info] response: ", response.data);
          dispatch(setLoading(false, ""));
          dispatch({
            type: SET_LEADERBOARD_SELECTED_USER,
            payload: response.data,
          });
        });
    } catch (e) {
      console.log("[error] ", e);
    }
  };
}

export function getLeaderboardLocal(userScore, username) {
  return async (dispatch, getState) => {
    console.log("[info] getLeaderboardLocal()");
    try {
      await axios
        .post(apiUrl + "/api/leaderboard/getlist", null, {
          params: {
            func: "get_list",
            userScore: userScore,
            username: username,
          },
        })
        .then((response) => {
          console.log("[info] response: ", response.data.leaderboardList);
          dispatch(setLoading(false));
          dispatch({
            type: SET_LEADERBOARD,
            payload: response.data.leaderboardList,
          });
        });
    } catch (e) {
      console.log("[error] ", e);
    }
  };
}

export function getLeaderboardUp(userScore, username) {
  return async (dispatch, getState) => {
    console.log("[info] getLeaderboardUp()");
    try {
      dispatch({
        type: SET_LEADERBOARD_BUTTON_DOWN,
        payload: "on",
      });
      await axios
        .post(apiUrl + "/api/leaderboard/getlist", null, {
          params: {
            func: "get_list",
            userScore: userScore,
            username: username,
          },
        })
        .then((response) => {
          console.log("[info] response: ", response.data.leaderboardList);
          dispatch({
            type: SET_LEADERBOARD,
            payload: response.data.leaderboardList,
          });
        });
    } catch (e) {
      console.log("[error] ", e);
    }
  };
}

export function getLeaderboardDown(userScore, username) {
  return async (dispatch, getState) => {
    console.log("[info] getLeaderboardDown()");
    try {
      await axios
        .post(apiUrl + "/api/leaderboard/getlist", null, {
          params: {
            func: "get_list",
            userScore: userScore,
            username: username,
          },
        })
        .then((response) => {
          if (response.data?.leaderboardError === "no_down_users") {
            dispatch({
              type: SET_LEADERBOARD_BUTTON_DOWN,
              payload: "off",
            });
          } else {
            console.log("[info] response: ", response.data.leaderboardList);
            dispatch({
              type: SET_LEADERBOARD,
              payload: response.data.leaderboardList,
            });
          }
        });
    } catch (e) {
      console.log("[error] ", e);
    }
  };
}

export function attackUser(username, keyValue, usernameVictim) {
  return async (dispatch, getState) => {
    console.log("[info] attackUser()");
    dispatch(setLoading(true, "all"));
    try {
      const noUnixnow = moment.utc().format();
      const now = moment.utc(noUnixnow).unix();
      await axios
        .post(apiUrl + "/api/quest/fight", null, {
          params: {
            func: "fight_player",
            username,
            key: keyValue,
            usernameVictim: usernameVictim,
            now,
          },
        })
        .then((response) => {
          console.log("[info] response: ", response.data);
          dispatch({
            type: SET_FIGHTLOG_ARENA,
            payload: response.data,
          });
          dispatch(doFetch(username, keyValue));
        });
    } catch (e) {
      console.log("[error] ", e);
    }
  };
}

export function questFightStart(specialKey, username) {
  return async (dispatch, getState) => {
    console.log("[info] questFightStart()");
    try {
      await axios
        .post(apiUrl + "/api/quest/fight", null, {
          params: {
            func: "fight_start",
            username,
            key: specialKey,
          },
        })
        .then((response) => {
          console.log("[info] response: ", response.data);
          dispatch({
            type: SET_FIGHTLOG,
            payload: response.data,
          });
          dispatch(doFetch(username, specialKey));
        });
    } catch (e) {
      console.log("[error] ", e);
    }
  };
}

export function setStatsLoading(state) {
  return async (dispatch, getState) => {
    console.log("[info] setStatsLoading  //", state);
    dispatch({
      type: SET_STATS_LOADING,
      payload: state,
    });
  };
}

export function finishArenaFight(username, key) {
  return async (dispatch, getState) => {
    console.log("[info] finishArenaFight()");
    try {
      await axios
        .post(apiUrl + `/api/quest/fight`, null, {
          params: {
            func: "arena_finish",
            username,
            key,
          },
        })
        .then((response) => {
          console.log("[info] response: ", response);
          if (response.data.questFinished) {
            console.log("[info] quest is finished right");
          } else {
            console.log("[info] quest wasnt finished!");
          }
          dispatch(doFetch(username, key));
        });
    } catch (e) {
      console.log("[error] ", e);
    }
  };
}

export function doFetch(username, specialKey, gameOpen) {
  return async (dispatch, getState) => {
    console.log("[info] doFetch()");
    try {
      const key = specialKey;
      await axios
        .post(apiUrl + `/api/user/login`, null, {
          params: {
            func: "key_login",
            username,
            key,
          },
        })
        .then((response) => {
          console.log("[info] response: ", response.data);
          dispatch(setStatsLoading(false));
          if (response.data.code === "1000") {
            console.log("[logout]");
            localStorage.removeItem("user");
            toast(`Logout successful! Goodbye`);
            setTimeout(() => {
              window.open("/", "_self");
            }, 2000);
          }
          dispatch({
            type: GET_FETCH,
            payload: response.data,
          });
          if (gameOpen === true) {
            console.log("gameOpen: ", true);
            dispatch(doDailyDateCheck(username, key));
          } else {
            console.log("gameOpen: ", false);
            dispatch(setLoading(false, ""));
            dispatch(setLoadingButton(false));
          }
        });
    } catch (e) {
      console.log("[error] ", e);
    }
  };
}

export function doDailyDateCheck(username, key) {
  return async (dispatch, getState) => {
    console.log("[info] doDailyDateCheck()");
    try {
      await axios
        .post(apiUrl + `/api/user/login`, null, {
          params: {
            func: "daily_check",
            username,
            key,
          },
        })
        .then((response) => {
          if (response.data.wasRefreshed === true) {
            console.log("[info] User was now refreshed, need to do fetch");
            dispatch(doFetch(username, key));
          } else {
            console.log("[info] User was already refreshed today");
          }
        });
    } catch (e) {
      console.log("[error] ", e);
    }
  };
}
