import { combineReducers } from "redux";
import usersReducer from "./users.reducer";
import loginReducer from "../../components/Login/store/reducers";
import registerReducer from "../../components/Register/store/reducers";
import gameReducer from "../../components/GameScene/store/reducers";

export default combineReducers({
  users: usersReducer,
  login: loginReducer,
  register: registerReducer,
  game: gameReducer,
});
