import { GET_REGISTER, RESET_FAIL, SET_LOADING, CHECK_USERNAME } from "../register.types";
import axios from "axios";
import apiUrl from "../../../../constants/";

export function doRegister(email, password, username, gender, classa, characterIndex) {
  return async (dispatch, getState) => {
    try {
      console.log("[info] doRegister()");
      const characterNumber = characterIndex + 1;
      await axios
        .post(apiUrl + `/api/user/register`, null, {
          params: {
            func: "do_register",
            email,
            password,
            username,
            gender,
            classa,
            characterNumber,
          },
        })
        .then((response) => {
          console.log("[info] response: ", response.data);
          dispatch({
            type: GET_REGISTER,
            payload: response.data,
          });
        });
    } catch (e) {
      console.log("[error] ", e);
    }
  };
}

export function checkUsername(username) {
  return async (dispatch, getState) => {
    try {
      console.log("[info] checkUsername()");
      await axios
        .post(apiUrl + `/api/user/register`, null, {
          params: {
            func: "check_username",
            username,
          },
        })
        .then((response) => {
          console.log("[info] response: ", response.data);
          dispatch({
            type: CHECK_USERNAME,
            payload: response.data,
          });
        });
    } catch (e) {
      console.log("[error] ", e);
    }
  };
}

export function doResetFail() {
  return async (dispatch, getState) => {
    console.log("[info] doResetFail()");
    dispatch({
      type: RESET_FAIL,
      payload: "",
    });
  };
}

export function setLoading(status) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_LOADING,
      payload: status,
    });
  };
}
