import { useState, useEffect, useRef } from "react";
import moment from "moment-timezone";
import { FullScreen, LeftPanel, LeftPanelButton, MainScreen, PackOfProfile, PackOfMiniButtons, MiniButton, ProfileLevel, PackOfProfilePic, ProfileProgressBarXP, ProfileEurodollar, ProfileLabel, ProfileProgressBar, ProfileRightPanel, ProfileLabelName, QuestTimeLabelStyle, WelcomeDiv, WelcomeLabel } from "../../styles/StyledGame";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket, faVolumeHigh, faVolumeXmark, faCircleQuestion, faGear, faUser } from "@fortawesome/free-solid-svg-icons";
import PubScreen from "./screens/PubScreen";
import HouseScreen from "./screens/HouseScreen";
import ArenaScreen from "./screens/ArenaScreen";
import DungeonsScreen from "./screens/DungeonsSceen";
import ArmorShopScreen from "./screens/ArmorShopScreen";
import WeaponShopScreen from "./screens/WeaponShopScreen";
import TrinketShopScreen from "./screens/TrinketShopScreen";
import CarWorkshopScreen from "./screens/CarWorkshopScreen";
import MailScreen from "./screens/MailScreen";
import GuildScreen from "./screens/GuildScreen";
import LeaderboardsScreen from "./screens/LeaderboardsScreen";
import { toast } from "react-toastify";
import ProfileScreen from "./screens/ProfileScreen";
import SettingsScreen from "./screens/SettingsScreen";
import LoadingComponent from "../LoadingComponent";

const Game = (props) => {
  const [screen, setScreen] = useState("home");
  const [cursorIsHidden, setCursorIsHidden] = useState(false);
  const [questLeftTime, setQuestLeftTime] = useState(1110000112312313);
  const [questFinished, setQuestFinished] = useState(false);
  const [isRunningQuestTime, setIsRunningQuestTime] = useState(false);
  const [fightPlayerMargin, setFightPlayerMargin] = useState(0);
  const [fightEnemyMargin, setFightEnemyMargin] = useState(0);
  const [loadingFill, setLoadingFill] = useState("0%");
  const [fightLoopStarted, setFightLoopStarted] = useState(false);
  const userStatus = props.user.quest_status;
  const questIsCanceled = props.questIsCanceled;
  const questIsSkipped = props.questIsSkipped;
  const userGender = props.user.gender;
  const userCharacterNumber = Number(props.user.character);
  const userFightLog = props.user.quest_fight.fightLog;
  const eurodollar = props.user.eurodollar.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  const caps = props.user?.caps.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  const questTimeTo = moment.unix(props.quest.quest_to).utc().format();
  const [fight, setFight] = useState({
    userHp: 100,
    userHpMax: 100,
    userAttack: 100,
    enemyHp: 100,
    enemyHpMax: 100,
    enemyAttack: 100,
    critical: false,
  });

  const userPic = `/assets/${userGender}/${userGender}Image${userCharacterNumber}.png`;

  const playClickSound = async () => {
    const clicksound = new Audio("/assets/audio/buttonClick.mp3");
    clicksound.volume = 0.09;
    clicksound.play();
  };

  //START QUEST FIGHT
  useEffect(() => {
    if (userStatus === "fighting" && screen === "pub" && !fightLoopStarted) {
      console.log("[fight] START fight loop. Enemy max HP: ", props.user.quest_fight.enemy.fight.hp);
      const enemyMaxHpNow = props.user?.quest_fight?.enemy?.fight?.hp ? props.user.quest_fight.enemy.fight.hp : userFightLog[0].hp + userFightLog[0].damage;
      const userMaxHpNow = props.user?.quest_fight?.user?.statistics?.hp ? props.user?.quest_fight?.user?.statistics?.hp : props.user.statistics.hp;
      setFight({
        userHp: userMaxHpNow,
        userHpMax: userMaxHpNow,
        userAttack: 0,
        enemyHp: enemyMaxHpNow,
        enemyHpMax: enemyMaxHpNow,
        enemyAttack: 0,
        critical: false,
      });
      setTimeout(() => {
        setFightLoopStarted(true);
        setQuestLeftTime(1110000112312313);
        setLoadingFill("0%");
        questFightLoop();
      }, 1500); //LOONGER FOR EDIT STYLE
    }
    props.handleFetch();
  }, [userStatus, screen]);

  //START QUEST FIGHT LOOP
  const questFightLoop = () => {
    console.log("[fight] full fight log: ", userFightLog);
    userFightLog.forEach((item, index) => {
      setTimeout(() => {
        console.log("[FIGHTLOG] hit ", item);
        if (item.who === "user") {
          //MOVE TO ENEMY
          for (let i = -10; i <= 45; i++) {
            setTimeout(() => {
              setFightPlayerMargin(i);
              if (i === 45) {
                for (let i = 45; i >= 0; i--) {
                  setTimeout(() => {
                    setFightPlayerMargin(i);
                  }, (45 - i) * 15);
                }
              }
            }, i * 1.8);
          }

          setFight((prevFight) => ({
            ...prevFight,
            userAttack: item.damage,
            enemyHp: item.hp,
            enemyAttack: 0,
            critical: item.critical,
          }));
          if (item.critical === true) {
            const hitCritic = new Audio("/assets/audio/hitCritic.mp3");
            hitCritic.volume = 0.05;
            hitCritic.play();
          } else {
            const hitDagger = new Audio("/assets/audio/hitDagger.mp3");
            hitDagger.volume = 0.05;
            hitDagger.play();
          }
          if (item.hp === 0) {
            const questWin = new Audio("/assets/audio/questWin.mp3");
            questWin.volume = 0.05;
            questWin.play();
            toast("You won!");
            console.log("[fight] User won");
            setTimeout(() => {
              props.handleFinishQuest();
              setQuestFinished(false);
              setFightLoopStarted(false);
            }, 1500);
          }
        } else if (item.who === "enemy") {
          //MOVE TO PLAYER
          for (let i = -10; i <= 45; i++) {
            setTimeout(() => {
              setFightEnemyMargin(i);
              if (i === 45) {
                for (let i = 45; i >= 0; i--) {
                  setTimeout(() => {
                    setFightEnemyMargin(i);
                  }, (45 - i) * 15);
                }
              }
            }, i * 1.8);
          }

          setFight((prevFight) => ({
            ...prevFight,
            enemyAttack: item.damage,
            userHp: item.hp,
            userAttack: 0,
            critical: item.critical,
          }));
          if (item.critical === true) {
            const hitCritic = new Audio("/assets/audio/hitCritic.mp3");
            hitCritic.volume = 0.05;
            hitCritic.play();
          } else {
            const hitSword = new Audio("/assets/audio/hitSword.mp3");
            hitSword.volume = 0.05;
            hitSword.play();
          }
          if (item.hp === 0) {
            const questLost = new Audio("/assets/audio/questLost.mp3");
            questLost.volume = 0.05;
            questLost.play();
            toast.error("You lost!");
            console.log("[fight] Enemy won");
            setTimeout(() => {
              props.handleFinishQuest();
              setQuestFinished(false);
              setFightLoopStarted(false);
            }, 1500);
          }
        }
      }, index * 1000);
    });
  };

  //START QUEST
  useEffect(() => {
    const realtimeNow = questPrepareTimes();
    realtimeNow.then((now) => {
      const timeNow = now.format();
      const isQuestFinished = timeNow > questTimeTo;
      if (isQuestFinished === false && Number(props.quest.quest_from) > 0 && questLeftTime === 1110000112312313) {
        console.log("[quest] START quest time loop , ", Number(props.quest.quest_from), " , ", questLeftTime);
        const timeInMill = Number(props.quest.time) * 60000;
        const timeDuration = moment.duration(timeInMill);
        const minutes = Math.floor(timeDuration.asMinutes()) % 60;
        const seconds = Math.floor(timeDuration.asSeconds()) % 60;
        setQuestLeftTime(minutes > 0 ? `${minutes}:${seconds}` : seconds);
        setIsRunningQuestTime(true);
      } else if (Number(props.quest.quest_from) > 0 && questLeftTime === 1110000112312313) {
        if (questFinished === false) {
          console.log("[quest] QUEST was finished already");
          props.questFightStart();
        }
      }
    });
  }, [questTimeTo, questLeftTime]);

  //GET NOW TIME
  async function questPrepareTimes() {
    const utcTime = moment.utc();
    return utcTime;
  }

  // [LOOP] Quest Time
  useEffect(() => {
    console.log("isRunningQuestTime: ", isRunningQuestTime);
    let intervalId;
    if (isRunningQuestTime) {
      intervalId = setInterval(() => {
        const tpcNow = questPrepareTimes();
        tpcNow.then((now) => {
          //time part
          const targetTime = moment.utc(moment.unix(props.quest.quest_to).format());
          const diffInMilliseconds = targetTime.diff(now);
          const duration = moment.duration(diffInMilliseconds);
          const minutes = Math.floor(duration.asMinutes()) % 60;
          const seconds = Math.floor(duration.asSeconds()) % 60;

          //percentage part
          const totalDuration = moment.duration(props.quest.quest_to - props.quest.quest_from, "seconds").asMilliseconds();
          const elapsedDuration = moment.duration(now - moment.unix(props.quest.quest_from)).asMilliseconds();
          const percentage = Math.min(Math.max(Math.round((elapsedDuration / totalDuration) * 100), 0), 100);
          const percentageString = `${percentage}%`;
          setLoadingFill(percentageString);

          if (minutes > 0 && diffInMilliseconds > 0) {
            setQuestLeftTime(`${minutes}:${seconds}`);
          } else if (diffInMilliseconds > 0) {
            setQuestLeftTime(`${seconds}`);
          }

          if (diffInMilliseconds > 0) {
          } else {
            if (!questFinished) {
              console.log("[quest] QUEST IS FINISHEEEED!!!");
              setQuestFinished(true);
              props.questFightStart();
              setIsRunningQuestTime(false);
              clearInterval(intervalId);
            }
          }
        });
      }, 1000); // Interval in milliseconds, adjust as needed
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isRunningQuestTime]);

  //[LOOP] END//
  useEffect(() => {
    if (questIsCanceled) {
      console.log("[quest] QUEST IS CANCELED!!!");
      setIsRunningQuestTime(false);
      setQuestLeftTime(1110000112312313);
      setLoadingFill("0%");
    }
  }, [questIsCanceled]);
  useEffect(() => {
    if (questIsSkipped) {
      console.log("[quest] QUEST IS SKIPPED!!!");
      setLoadingFill("100%");
      setIsRunningQuestTime(false);
      setQuestFinished(true);
      setQuestLeftTime(1110000112312313);
      props.questFightStart();
    }
  }, [questIsSkipped]);

  //SET TIME LEFT OF QUEST IN TAB
  useEffect(() => {
    document.title = questLeftTime !== 1110000112312313 ? `[${questLeftTime !== "0" ? questLeftTime : "DONE"}] - Apocalypse of Nomads` : "Apocalypse of Nomads";
  }, [questLeftTime]);

  const QuestTimeLabel = () => {
    const realtime = questLeftTime !== "0" ? questLeftTime : "DONE";
    return (
      <>
        {"PUB ["}
        <QuestTimeLabelStyle>{realtime.toString()}</QuestTimeLabelStyle>
        {"]"}
      </>
    );
  };

  const onAttackUser = (selectedUser) => {
    setScreen("arena");
    props.handleAttackUser(selectedUser);
  };

  return (
    <>
      {props.loading.status && props.loading.where === "all" && <LoadingComponent />}
      <FullScreen cursorIsHidden={cursorIsHidden}>
        <LeftPanel>
          <PackOfProfile>
            <ProfilePic race={props.user.race} userPic={userPic} level={props.user.level} xp={props.user.xp} xp_max={props.user.xp_max} setScreen={setScreen} playClickSound={playClickSound} />
            <ProfileRightPanel>
              <ProfileLabelName>
                <h3>{props.user.username_visible}</h3>
              </ProfileLabelName>
              <ProfileLabel>
                <h3
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                >
                  <ProfileEurodollar>€$ </ProfileEurodollar>
                  <a>{eurodollar}</a>
                </h3>
                <h3
                  style={{
                    marginTop: "0.3rem",
                    marginBottom: 0,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img draggable={false} loading="lazy" width={20} height={"auto"} src="/assets/capImage.png" alt="cap" />

                  <a style={{ marginLeft: "0.2rem" }}> {caps}</a>
                </h3>
              </ProfileLabel>
            </ProfileRightPanel>
          </PackOfProfile>
          <LeftPanelButton
            selected={screen === "pub"}
            onClick={() => {
              playClickSound();
              setScreen("pub");
            }}
          >
            {questLeftTime === 1110000112312313 && "PUB"}
            {questLeftTime !== 1110000112312313 && <QuestTimeLabel />}
          </LeftPanelButton>
          <LeftPanelButton
            selected={screen === "arena"}
            onClick={() => {
              playClickSound();
              setScreen("arena");
            }}
          >
            ARENA
            {props.user.quest_status === "arena" && (
              <>
                {" "}
                [<a style={{ color: "orange" }}>FIGHT</a>]
              </>
            )}
          </LeftPanelButton>
          <LeftPanelButton
            selected={screen === "dungeons"}
            onClick={() => {
              playClickSound();
              setScreen("dungeons");
            }}
          >
            DUNGEONS ⚠️
          </LeftPanelButton>
          <LeftPanelButton
            selected={screen === "armorShop"}
            onClick={() => {
              playClickSound();
              setScreen("armorShop");
            }}
          >
            ARMOR SHOP
          </LeftPanelButton>
          <LeftPanelButton
            selected={screen === "weaponShop"}
            onClick={() => {
              playClickSound();
              setScreen("weaponShop");
            }}
          >
            WEAPON SHOP ⚠️
          </LeftPanelButton>
          <LeftPanelButton
            selected={screen === "trinketShop"}
            onClick={() => {
              playClickSound();
              setScreen("trinketShop");
            }}
          >
            TRINKET SHOP ⚠️
          </LeftPanelButton>
          <LeftPanelButton
            selected={screen === "carWorkshop"}
            onClick={() => {
              playClickSound();
              setScreen("carWorkshop");
            }}
          >
            CAR WORKSHOP ⚠️
          </LeftPanelButton>
          <LeftPanelButton
            selected={screen === "house"}
            onClick={() => {
              playClickSound();
              setScreen("house");
            }}
          >
            HOUSE ⚠️
          </LeftPanelButton>
          <LeftPanelButton
            selected={screen === "guild"}
            onClick={() => {
              playClickSound();
              setScreen("guild");
            }}
          >
            GUILD ⚠️
          </LeftPanelButton>
          <LeftPanelButton
            selected={screen === "mail"}
            onClick={() => {
              playClickSound();
              setScreen("mail");
            }}
          >
            MAIL ⚠️
          </LeftPanelButton>
          <LeftPanelButton
            selected={screen === "leaderboards"}
            onClick={() => {
              playClickSound();
              setScreen("leaderboards");
            }}
          >
            LEADERBOARDS
          </LeftPanelButton>
          <PackOfMiniButtons>
            <MiniButton
              selected={screen === "profile"}
              onClick={() => {
                playClickSound();
                setScreen("profile");
              }}
            >
              <FontAwesomeIcon icon={faUser} style={{ color: "rgba(42, 39, 37, 0.8)" }} />
            </MiniButton>
            <MiniButton
              selected={screen === "settings"}
              onClick={() => {
                playClickSound();
                setScreen("settings");
              }}
            >
              <FontAwesomeIcon icon={faGear} style={{ color: "rgba(42, 39, 37, 0.8)" }} />
            </MiniButton>
            <MiniButton
              onClick={() => {
                playClickSound();
              }}
            >
              <FontAwesomeIcon icon={faCircleQuestion} style={{ color: "rgba(42, 39, 37, 0.8)" }} />
            </MiniButton>
            <MiniButton
              onClick={() => {
                playClickSound();
                if (props.backmusicPlaying) {
                  props.setBackmusicPlaying(false);
                  props.audioBackmusicRef.current.volume = 0.03;
                  props.audioBackmusicRef.current.pause();
                } else {
                  props.setBackmusicPlaying(true);
                  props.audioBackmusicRef.current.volume = 0.03;
                  props.audioBackmusicRef.current.loop = true;
                  props.audioBackmusicRef.current.play();
                }
              }}
            >
              <FontAwesomeIcon icon={props.backmusicPlaying ? faVolumeHigh : faVolumeXmark} style={{ color: "rgba(42, 39, 37, 0.8)" }} />
            </MiniButton>
            {/* logout */}
            <MiniButton hidden={props.loadingButtonDisabled}>
              <FontAwesomeIcon
                onClick={() => {
                  playClickSound();
                  props.handleLogout();
                }}
                icon={faRightFromBracket}
                style={{ color: "rgba(165, 21, 21, 0.8)" }}
              />
            </MiniButton>
          </PackOfMiniButtons>
        </LeftPanel>
        <MainScreen>
          <ShowMainScreen setCursorIsHidden={setCursorIsHidden} screen={screen} userPic={userPic} fight={fight} questLeftTime={questLeftTime} fightPlayerMargin={fightPlayerMargin} fightEnemyMargin={fightEnemyMargin} loadingFill={loadingFill} onAttackUser={onAttackUser} props={props} />
        </MainScreen>
      </FullScreen>
    </>
  );
};

export default Game;

const ShowMainScreen = ({ screen, userPic, questLeftTime, fight, loadingFill, fightPlayerMargin, fightEnemyMargin, onAttackUser, props, setCursorIsHidden }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    // Simulating label loading delay
    const timer = setTimeout(() => {
      setVisible(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  switch (screen) {
    case "home":
      return (
        <WelcomeDiv>
          <WelcomeLabel visible={visible}>Apocalypse of Nomads</WelcomeLabel>
        </WelcomeDiv>
      );
    case "pub":
      return <PubScreen questLeftTime={questLeftTime} userPic={userPic} fight={fight} fightPlayerMargin={fightPlayerMargin} fightEnemyMargin={fightEnemyMargin} loadingFill={loadingFill} props={props} />;
    case "arena":
      return <ArenaScreen userPic={userPic} props={props} />;
    case "dungeons":
      return <DungeonsScreen />;
    case "armorShop":
      return <ArmorShopScreen setCursorIsHidden={setCursorIsHidden} props={props} />;
    case "weaponShop":
      return <WeaponShopScreen />;
    case "trinketShop":
      return <TrinketShopScreen />;
    case "carWorkshop":
      return <CarWorkshopScreen />;
    case "house":
      return <HouseScreen />;
    case "guild":
      return <GuildScreen />;
    case "mail":
      return <MailScreen />;
    case "leaderboards":
      return <LeaderboardsScreen props={props} onAttackUser={onAttackUser} />;
    case "profile":
      return <ProfileScreen setCursorIsHidden={setCursorIsHidden} userPic={userPic} props={props} />;
    case "settings":
      return <SettingsScreen props={props} />;
    default:
      break;
  }
};

const ProfilePic = ({ race, userPic, level, xp, xp_max, setScreen, playClickSound }) => {
  let xpPercent = (xp / xp_max) * 100;
  if (xpPercent >= 97) {
    xpPercent = 96.5;
  }

  return (
    <PackOfProfilePic
      onClick={() => {
        playClickSound();
        setScreen("profile");
      }}
    >
      <img loading="lazy" draggable={false} src={userPic} height={"100%"} />

      <ProfileProgressBar>
        <ProfileProgressBarXP widthPercent={xpPercent}>
          <ProfileLevel>{level} level</ProfileLevel>
        </ProfileProgressBarXP>
      </ProfileProgressBar>
    </PackOfProfilePic>
  );
};
