import { useEffect, useState } from "react";
import { LeaderBoardPackage, SplitScreenContainer, SplitScreenLeft, SplitScreenRight, LeaderBoardList, LeaderBoardTable, LeaderBoardThead, LeaderBoardTh, LeaderBoardTd, LeaderBoardRank, LeaderBoardTr, LeaderBoardImage, LeaderBoardMoveButton, ListUserImage, ProfileMainContainer, ImageProfileBorder, ProfileLeftArmorContainer, ProfileArmorBox, ProfileRightArmorContainer, ProfileStyledColumn, ProfileTable, ProfileNameLabelStyle, LeaderBoardButton } from "../../../styles/StyledLeaderBoards";
import TooltipPopup from "../../PopupsVersions/TooltipPopup";
import InfoPopup from "../../PopupsVersions/InfoPopup";
import { ProfileStyledColumnMain } from "../../../styles/StyledProfile";

const ProfileImage = ({ selectedUserPic, selectedUser, props }) => (
  <ImageProfileBorder>
    <img
      loading="lazy"
      draggable={false}
      alt="altProfileImage"
      src={selectedUserPic}
      style={{
        maxWidth: "90%",
        height: "auto",
        borderRadius: "5%",
        border: "2px solid rgba(132, 77, 53, 0.5)",
        boxShadow: "2px 2px 15px rgba(0, 0, 0, 0.9)",
      }}
    />
    <ProfileNameLabelStyle>{selectedUser.username_visible}</ProfileNameLabelStyle>
  </ImageProfileBorder>
);

const ProfileLeftArmor = ({ props, stateSlots, handleItemLeave, handleInfoLeave, handleItemHover, handleMouseMove }) => {
  return (
    <ProfileLeftArmorContainer onMouseLeave={handleItemLeave}>
      {stateSlots.map((slot, index) => {
        if (index < 6) {
          return (
            <ProfileArmorBox
              onMouseEnter={() => {
                handleItemHover(slot);
                handleInfoLeave();
              }}
              onMouseMove={handleMouseMove}
            >
              {slot?.id ? (
                <div
                  style={{
                    userSelect: "none",
                    display: "flex",
                  }}
                >
                  <img loading="lazy" draggable={false} alt="altContainerLeft" src={slot.image} style={{ maxWidth: "100%", height: "auto" }} />
                </div>
              ) : (
                <div
                  style={{
                    fontSize: "60%",
                    textAlign: "center",
                    color: "gray",
                  }}
                >
                  {slot.type}
                </div>
              )}
            </ProfileArmorBox>
          );
        }
      })}
    </ProfileLeftArmorContainer>
  );
};

const ProfileRightArmor = ({ props, stateSlots, handleItemLeave, handleItemHover, handleMouseMove, handleInfoLeave }) => (
  <ProfileRightArmorContainer>
    {stateSlots.map((slot, index) => {
      if (index >= 6) {
        return (
          <ProfileArmorBox onMouseEnter={() => handleInfoLeave()}>
            {slot?.id ? (
              <div
                style={{
                  userSelect: "none",
                  display: "flex",
                }}
              >
                <img loading="lazy" draggable={false} alt="altContainerLeft" src={slot.image} style={{ maxWidth: "100%", height: "auto" }} />
              </div>
            ) : (
              <div
                style={{
                  fontSize: "60%",
                  textAlign: "center",
                  color: "gray",
                }}
              >
                {slot.type}
              </div>
            )}
          </ProfileArmorBox>
        );
      }
    })}
  </ProfileRightArmorContainer>
);

const StatsForm = ({ selectedUser, handleItemLeave, handleInfoHover, handleInfoLeave, handleMouseMove }) => (
  <ProfileTable
    onMouseLeave={() => {
      handleInfoLeave();
      handleItemLeave();
    }}
  >
    <tbody>
      <tr>
        <ProfileStyledColumnMain>{"❤️   HP"}</ProfileStyledColumnMain>
        <ProfileStyledColumnMain colSpan={3} orange>
          {selectedUser.statistics.hp}
        </ProfileStyledColumnMain>
      </tr>
      <tr>
        <ProfileStyledColumnMain>{"⚔️   Damage"}</ProfileStyledColumnMain>
        <ProfileStyledColumnMain colSpan={3} orange>
          {selectedUser.statistics.attack}
        </ProfileStyledColumnMain>
      </tr>
      <tr>
        <ProfileStyledColumnMain>{"🍀   Critical"}</ProfileStyledColumnMain>
        <ProfileStyledColumnMain colSpan={3} orange>
          {`${selectedUser.statistics.critical.toFixed(1)}%`}
        </ProfileStyledColumnMain>
      </tr>
      <tr>
        <ProfileStyledColumnMain>{"🛡️   Armor"}</ProfileStyledColumnMain>
        <ProfileStyledColumnMain colSpan={3} orange>
          {`${selectedUser.statistics.armor} (${(Number(selectedUser.statistics.armor / selectedUser.level) > 50 ? 50 : Number(selectedUser.statistics.armor / selectedUser.level)).toFixed(2)}%)`}
        </ProfileStyledColumnMain>
      </tr>
      <tr
        onMouseEnter={() => {
          handleInfoHover("strength");
        }}
        onMouseMove={handleMouseMove}
        onMouseLeave={() => {
          handleInfoLeave();
        }}
      >
        <ProfileStyledColumn>STRENGTH</ProfileStyledColumn>
        <ProfileStyledColumn>{selectedUser.statistics.strength}</ProfileStyledColumn>
      </tr>
      <tr
        onMouseEnter={() => {
          handleInfoHover("stamina");
        }}
        onMouseMove={handleMouseMove}
        onMouseLeave={() => {
          handleInfoLeave();
        }}
      >
        <ProfileStyledColumn>STAMINA</ProfileStyledColumn>
        <ProfileStyledColumn>{selectedUser.statistics.stamina}</ProfileStyledColumn>
      </tr>
      <tr
        onMouseEnter={() => {
          handleInfoHover("dexterity");
        }}
        onMouseMove={handleMouseMove}
        onMouseLeave={() => {
          handleInfoLeave();
        }}
      >
        <ProfileStyledColumn>DEXTERITY</ProfileStyledColumn>
        <ProfileStyledColumn>{selectedUser.statistics.dexterity}</ProfileStyledColumn>
      </tr>
      <tr
        onMouseEnter={() => {
          handleInfoHover("intelligence");
        }}
        onMouseMove={handleMouseMove}
        onMouseLeave={() => {
          handleInfoLeave();
        }}
      >
        <ProfileStyledColumn>INTELLIGENCE</ProfileStyledColumn>
        <ProfileStyledColumn>{selectedUser.statistics.intelligence}</ProfileStyledColumn>
      </tr>
      <tr
        onMouseEnter={() => {
          handleInfoHover("lucky");
        }}
        onMouseMove={handleMouseMove}
        onMouseLeave={() => {
          handleInfoLeave();
        }}
      >
        <ProfileStyledColumn>LUCKY</ProfileStyledColumn>
        <ProfileStyledColumn>{selectedUser.statistics.lucky}</ProfileStyledColumn>
      </tr>
    </tbody>
  </ProfileTable>
);

const LeaderboardsScreen = ({ props, onAttackUser }) => {
  const [canAttack, setCanAttack] = useState(false);
  const [isCountdownStarted, setIsCountdownStarted] = useState(false);
  const [timeLeft, setTimeLeft] = useState("");
  const [wasSelectedUser, setWasSelectedUser] = useState();
  const selectedUser = props.leaderboardSelectedUser;
  const leaderboardList = props.leaderboardList;
  const leaderboardButtonDown = props.leaderboardButtonDown;
  const selectedUserPic = selectedUser?.username && `/assets/${selectedUser.gender}/${selectedUser.gender}Image${selectedUser.character}.png`;
  const [popupItem, setPopupItem] = useState(null);
  const [popupInfo, setPopupInfo] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (event) => {
    setMousePosition({ x: event.clientX, y: event.clientY });
  };
  const handleItemHover = (item) => {
    setPopupItem(item);
  };
  const handleItemLeave = () => {
    setPopupItem(null);
  };
  const handleInfoHover = (item) => {
    setPopupInfo(item);
  };
  const handleInfoLeave = () => {
    setPopupInfo(null);
  };

  useEffect(() => {
    let timer;

    const formatTime = (time) => {
      const minutes = Math.floor(time / 60);
      const seconds = time % 60;
      return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    };

    const startCountdown = () => {
      const currentTime = Math.floor(Date.now() / 1000);
      const remainingTime = props.user.arena_fight_next.client - currentTime;

      if (remainingTime <= 0) {
        clearInterval(timer);
        setTimeLeft(formatTime(0));
        setCanAttack(true);
      } else {
        setTimeLeft(formatTime(remainingTime));
      }
    };

    if (isCountdownStarted) {
      startCountdown();
      timer = setInterval(startCountdown, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [isCountdownStarted, props.user.arena_fight_next.client]);

  const playClickSound = async () => {
    const clicksound = new Audio("/assets/audio/buttonClick.mp3");
    clicksound.volume = 0.09;
    clicksound.play();
  };

  const onDownButtonClick = async () => {
    if (leaderboardList?.length > 0) {
      props.onLeaderBoardDownClick(leaderboardList[leaderboardList.length - 1].score, leaderboardList[leaderboardList.length - 1].username);
    }
  };
  const onUpButtonClick = async () => {
    if (leaderboardList?.length > 0) {
      props.onLeaderBoardUpClick(leaderboardList[0].score, leaderboardList[0].username);
    }
  };

  const handleStartCountdown = () => {
    setIsCountdownStarted(true);
  };

  useEffect(() => {
    props.setLoading(true, "all");
    props.handleCleanLeaderboardSelectedUser();
    props.getLeaderboardLocal(props.user.score, props.user.username);
    const dateNow = new Date();
    const dateNowUnix = Math.floor(dateNow.getTime() / 1000);

    if (props.user.arena_fight_next.client < dateNowUnix) {
      //YOU CAN FIGHT
      console.log("dateNowUnix: ", dateNowUnix);
      console.log("arena_fight_next: ", props.user.arena_fight_next.client);
      console.log("yes");
      setCanAttack(true);
    } else {
      //CANNOT FIGHT, YOU MUST WAIT
      console.log("dateNowUnix: ", dateNowUnix);
      console.log("arena_fight_next: ", props.user.arena_fight_next.client);
      console.log("no");
      setCanAttack(false);
      handleStartCountdown();
    }
  }, []);

  return (
    <LeaderBoardPackage>
      <SplitScreenContainer>
        <SplitScreenLeft>
          <LeaderBoardList>
            <LeaderBoardMoveButton
              disabled={leaderboardList.some((item) => item.position === 1)}
              onClick={() => {
                playClickSound();
                onUpButtonClick();
              }}
            >
              ↑↑↑
            </LeaderBoardMoveButton>

            <LeaderBoardTable>
              <LeaderBoardThead>
                <LeaderBoardTr noHover>
                  <LeaderBoardTh>Rank</LeaderBoardTh>
                  <LeaderBoardTh></LeaderBoardTh>
                  <LeaderBoardTh>Username</LeaderBoardTh>
                  <LeaderBoardTh>Level</LeaderBoardTh>
                  <LeaderBoardTh>Honor</LeaderBoardTh>
                </LeaderBoardTr>
              </LeaderBoardThead>
              <tbody>
                {leaderboardList?.length > 0 &&
                  leaderboardList.map((item) => (
                    <LeaderBoardTr
                      key={item.username}
                      onClick={() => {
                        if (wasSelectedUser !== item.username) {
                          playClickSound();
                          props.setLeaderboardSelectedUser(item.username);
                          setWasSelectedUser(item.username);
                        }
                      }}
                    >
                      <LeaderBoardTd isUser={item.username === props.user.username} isSelectedUser={item.username === selectedUser?.username}>
                        <LeaderBoardRank rankColor={item.position === 1 ? "#ffc600" : item.position === 2 ? "#fefffe" : item.position === 3 ? "#cd3b00" : "#805a4b"} rankSize={item.position === 1 ? "1.6rem" : item.position === 2 ? "1.4rem" : item.position === 3 ? "1.2rem" : "1rem"}>
                          {" "}
                          {item.position}
                        </LeaderBoardRank>
                      </LeaderBoardTd>
                      <LeaderBoardTd
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        noPadding
                        isUser={item.username === props.user.username}
                        isSelectedUser={item.username === selectedUser?.username}
                      >
                        <LeaderBoardImage rankColor={item.position === 1 ? "0.1rem solid #ffc600" : item.position === 2 ? "0.1rem solid #fefffe" : item.position === 3 ? "0.1rem solid #cd3b00" : "0.1rem solid rgba(67, 47, 38, 0.8)"}>
                          <ListUserImage draggable={false} src={`/assets/${item.gender}/${item.gender}Image${item.character}.png`} alt="asdad" width={"100%"} height={"100%"} />
                        </LeaderBoardImage>
                      </LeaderBoardTd>
                      <LeaderBoardTd
                        style={{
                          fontWeight: "bold",
                          userSelect: "text",
                          cursor: "url('/assets/mouseArrow.png'), auto",
                        }}
                        isUser={item.username === props.user.username}
                        isSelectedUser={item.username === selectedUser?.username}
                      >
                        {item.username_visible}
                      </LeaderBoardTd>
                      <LeaderBoardTd isUser={item.username === props.user.username} isSelectedUser={item.username === selectedUser?.username}>
                        {item.level}
                      </LeaderBoardTd>
                      <LeaderBoardTd isUser={item.username === props.user.username} isSelectedUser={item.username === selectedUser?.username}>
                        {item.score}
                      </LeaderBoardTd>
                    </LeaderBoardTr>
                  ))}
              </tbody>
            </LeaderBoardTable>
            <LeaderBoardMoveButton
              disabled={!leaderboardButtonDown}
              onClick={() => {
                playClickSound();
                onDownButtonClick();
              }}
            >
              ↓↓↓
            </LeaderBoardMoveButton>
          </LeaderBoardList>
        </SplitScreenLeft>
        <SplitScreenRight onMouseLeave={handleItemLeave}>
          {selectedUser?.username && (
            <ProfileMainContainer
              style={{ justifyContent: "center" }}
              onMouseLeave={() => {
                handleInfoLeave();
                handleItemLeave();
              }}
            >
              <ProfileLeftArmor onMouseLeave={handleItemLeave} handleMouseMove={handleMouseMove} handleItemHover={handleItemHover} handleItemLeave={handleItemLeave} handleInfoLeave={handleInfoLeave} stateSlots={selectedUser.slots} props={props} />
              <ProfileImage onMouseLeave={handleItemLeave} handleInfoLeave={handleInfoLeave} selectedUserPic={selectedUserPic} selectedUser={selectedUser} props={props} />
              <ProfileRightArmor onMouseLeave={handleItemLeave} handleMouseMove={handleMouseMove} handleItemHover={handleItemHover} handleItemLeave={handleItemLeave} handleInfoLeave={handleInfoLeave} stateSlots={selectedUser.slots} props={props} />
            </ProfileMainContainer>
          )}
          {selectedUser?.username && (
            <div style={{ width: "90%", display: "flex" }}>
              <StatsForm handleInfoHover={handleInfoHover} handleInfoLeave={handleInfoLeave} handleItemLeave={handleItemLeave} handleMouseMove={handleMouseMove} onMouseMove={handleItemLeave} style={{ justifyContent: "center" }} selectedUser={selectedUser} />

              <LeaderBoardButton
                onMouseLeave={handleItemLeave}
                onClick={() => {
                  playClickSound();
                  onAttackUser(selectedUser);
                }}
                hidden={selectedUser?.username === props.user.username}
                disabled={!canAttack || props.user.quest_status === "questing" || props.user.quest_status === "fighting" || props.user.quest_status === "arena"}
              >
                {!canAttack ? `${timeLeft}` : props.user.quest_status === "questing" ? "Cannot while quest" : props.user.quest_status === "fighting" ? "Cannot while fighting" : props.user.quest_status === "arena" ? "Cannot while fighting" : "ATTACK"}
              </LeaderBoardButton>
            </div>
          )}
        </SplitScreenRight>
      </SplitScreenContainer>
      {popupItem && <TooltipPopup props={props} item={popupItem} position={mousePosition} onMouseLeave={handleItemLeave} />}
      {popupInfo && <InfoPopup item={popupInfo} position={mousePosition} handleClearPopup={handleInfoLeave} onMouseLeave={handleInfoLeave} />}
    </LeaderBoardPackage>
  );
};

export default LeaderboardsScreen;
