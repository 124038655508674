import styled from "styled-components";

export const ItemName = styled.a`
  color: lightgray;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.8);
`;

export const PopupContainer = styled.div.attrs((props) => ({
  style: {
    top: props.position.y + 10 + "px",
    left: props.position.x + 10 + "px",
  },
}))`
  position: fixed;
  border: 2px solid rgba(132, 77, 53, 0.4);
  width: 20%;
  background-color: rgba(106, 57, 39, 0.2);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  padding: 10px;
  border-radius: 1rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.8);
  z-index: 999;
  font-weight: bold;
`;

export const PopupContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const PopupStats = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PopupStat = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
`;
