import { GET_LOGIN, RESET_FAIL, SET_LOADING, SET_DISABLED_LOGIN_BUTTON, SET_ALLOWED_LOGIN_BUTTON } from "../login.types";
import axios from "axios";
import apiUrl from "../../../../constants/";

export function doLogin(username, password) {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoginButtonDisabled(true));
      const res = await axios
        .post(apiUrl + `/api/user/login`, null, {
          params: {
            func: "normal_login",
            username,
            password,
          },
        })
        .then((response) => {
          console.log("[info] response: ", response.data);
          if (response.data.error === true) dispatch(setLoginButtonDisabled(false));

          dispatch({
            type: GET_LOGIN,
            payload: response.data,
          });
        });
    } catch (e) {
      console.log("[error] ", e);
      dispatch(setLoginButtonDisabled(false));
      dispatch({
        type: GET_LOGIN,
        payload: { code: "0101", error: true, message: "Lost connection to the server" },
      });
    }
  };
}

export function setLoginButtonDisabled(disabled) {
  return async (dispatch, getState) => {
    if (disabled) {
      dispatch({ type: SET_DISABLED_LOGIN_BUTTON });
    } else {
      dispatch({ type: SET_ALLOWED_LOGIN_BUTTON });
    }
  };
}

export function setLoading(status) {
  console.log("loading status: ", status);
  return async (dispatch, getState) => {
    dispatch({
      type: SET_LOADING,
      payload: status,
    });
  };
}

export function doResetFail() {
  return async (dispatch, getState) => {
    dispatch({
      type: RESET_FAIL,
      payload: "",
    });
  };
}
