const MailScreen = () => {
  return (
    <center>
      <h1>MailScreen</h1>
      <img loading="lazy" width={"25%"} draggable={false} alt="altWorkInProgress" src={"/assets/workInProgress.png"} />
    </center>
  );
};

export default MailScreen;
