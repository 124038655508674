import React from "react";
import styled from "styled-components";
import { GameLoadingText, RotatingImage } from "../styles/StyledGame";

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(18, 18, 18, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  cursor: url("/assets/mouseArrow.png"), auto;
`;

const LoadingComponent = () => {
  return (
    <LoadingOverlay>
      <GameLoadingText>
        <RotatingImage draggable={false} src="/assets/Cir.png" width={200} height={"auto"} alt="asdad" />
        <img
          loading="lazy"
          draggable={false}
          style={{
            position: "absolute",
            translate: "-50% -50%",
            userSelect: "none",
          }}
          src="/assets/A.png"
          width={200}
          height={"auto"}
          alt="asdaasdad"
        />
      </GameLoadingText>
    </LoadingOverlay>
  );
};

export default LoadingComponent;
