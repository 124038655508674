const CarWorkshopScreen = () => {
  return (
    <center>
      <h1>CarWorkshopScreen</h1>
      <img width={"25%"} loading="lazy" draggable={false} alt="altWorkInProgress" src={"/assets/workInProgress.png"} />
    </center>
  );
};

export default CarWorkshopScreen;
