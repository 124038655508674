import { GET_REGISTER, RESET_FAIL, SET_LOADING, CHECK_USERNAME } from "../register.types";

const initialState = {
  user: {},
  incorrect: false,
  errorMessage: "",
  loading: false,
  usernameUsed: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case CHECK_USERNAME:
      if (action.payload.error === true) {
        console.log("[error] ", action.payload.message);
        const errorMessage = action.payload.message.replace(").", "");
        const realErrorMessage = errorMessage.replace("Firebase: Error (auth/", "");
        return {
          ...state,
          user: { stat: "loading" },
          incorrect: true,
          errorMessage: realErrorMessage,
        };
      }
      return {
        ...state,
        usernameUsed: action.payload.used,
      };
    case GET_REGISTER:
      if (action.payload.error === true) {
        console.log("[error] ", action.payload.message);
        const errorMessage = action.payload.message.replace(").", "");
        const realErrorMessage = errorMessage.replace("Firebase: Error (auth/", "");
        return {
          ...state,
          user: {},
          incorrect: true,
          errorMessage: realErrorMessage,
        };
      } else {
        console.log(action.payload);
        return {
          ...state,
          user: action.payload.user,
          incorrect: false,
        };
      }

    case RESET_FAIL:
      console.log("[RESET_FAIL]");
      return {
        ...state,
        incorrect: false,
        errorMessage: "",
        usernameUsed: "",
      };
    default:
      return state;
  }
}
