const DungeonsScreen = () => {
  return (
    <center>
      <h1>DungeonsScreen</h1>
      <img width={"25%"} loading="lazy" draggable={false} alt="altWorkInProgress" src={"/assets/workInProgress.png"} />
    </center>
  );
};

export default DungeonsScreen;
