export const GET_FETCH = "GET_FETCH";
export const RESET_FAIL = "RESET_FAIL";
export const SET_FIGHTLOG = "SET_FIGHTLOG";
export const SET_STATS_LOADING = "SET_STATS_LOADING";
export const SET_LEADERBOARD = "SET_LEADERBOARD";
export const SET_LEADERBOARD_BUTTON_DOWN = "SET_LEADERBOARD_BUTTON_DOWN";
export const SET_LEADERBOARD_SELECTED_USER = "SET_LEADERBOARD_SELECTED_USER";
export const CLEAN_LEADERBOARD_SELECTED_USER = "CLEAN_LEADERBOARD_SELECTED_USER";
export const SET_QUEST_CANCELED = "SET_QUEST_CANCELED";
export const SET_QUEST_ALLOWED = "SET_QUEST_ALLOWED";
export const SET_QUEST_SKIPPED = "SET_QUEST_SKIPPED";
export const SET_QUEST_UNSKIPPED = "SET_QUEST_UNSKIPPED";
export const SET_LOADINGBUTTON_DISABLED = "SET_LOADINGBUTTON_DISABLED";
export const SET_LOADINGBUTTON_ALLOWED = "SET_LOADINGBUTTON_ALLOWED";
export const SET_LOADING = "SET_LOADING";
export const SET_FIGHTLOG_ARENA = "SET_FIGHTLOG_ARENA";
