import { useEffect, useState } from "react";
import { PubBackground, PubPackage, PubQuestMan, QuestWindow, CloseButton, QuestWindowHeader, QuestWindowBackground, QuestTabBackground, PubLoadingBar, PubNameOfQuest, PubLoadingBarFill, PubTabButton, PubTimeBar, Eurodollar, MessageContainer, MessageTitle, MessageMessage, MessageRewardTitle, MessageXp, MessageMoney, MessageTime, MessageBottom, MessageSelectButton, FightContainer, FightPlayerSide, FightEnemySide, FightFrameEnemy, FightFramePlayer, FightPicture, FightNameLabel, FightFrameContainer, FightHpFrame, FightHpFill, FightHpLabel, FightFrameLine, FightVsText, FightHpDeficitUser, FightHpDeficitEnemy, BlurEnding, FightStatLabel, FightStatTable, FightStatColumn, PubCancelRunningQuest, PubSkipRunningQuest, PubEnergyBar, PubEnergyBarFill, PubEnergyLabel } from "../../../styles/StyledPub";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareXmark } from "@fortawesome/free-solid-svg-icons";

const PubScreen = ({ props, userPic, questLeftTime, loadingFill, fight, fightPlayerMargin, fightEnemyMargin }) => {
  const [selectedTab, setSelectedTab] = useState(1);
  const [shakingEnemy, setShakingEnemy] = useState(false);
  const [shakingPlayer, setShakingPlayer] = useState(false);

  const [dialog, setDialog] = useState({
    message: "Hello dear traveler!",
    visible: false,
    selected: 1,
  });

  let userHpPercent = (fight.userHp / fight.userHpMax) * 100;
  let enemyHpPercent = (fight.enemyHp / fight.enemyHpMax) * 100;

  const playClickSound = async () => {
    const clicksound = new Audio("/assets/audio/buttonClick.mp3");
    clicksound.volume = 0.09;
    clicksound.play();
  };

  //SHAKE ENEMY
  useEffect(() => {
    if (fight.userAttack !== 0) {
      setTimeout(() => {
        setShakingEnemy(true);
        setTimeout(() => {
          setShakingEnemy(false);
        }, 500); // Reset shaking state after 0.5s (same duration as the animation)
      }, 125);
    }
  }, [fight.userAttack]);

  //SHAKE PLAYER
  useEffect(() => {
    if (fight.enemyAttack !== 0) {
      setTimeout(() => {
        setShakingPlayer(true);
        setTimeout(() => {
          setShakingPlayer(false);
        }, 500); // Reset shaking state after 0.5s (same duration as the animation)
      }, 125);
    }
  }, [fight.enemyAttack]);

  const taskTime = (time) => {
    const timeInMill = Number(time) * 60000;
    const timeDuration = moment.duration(timeInMill);
    const minutes = Math.floor(timeDuration.asMinutes()) % 60;
    const seconds = Math.floor(timeDuration.asSeconds()) % 60;
    if (minutes > 0 && seconds > 0) {
      return `${minutes} minutes and ${seconds} seconds`;
    } else if (minutes > 0) {
      return `${minutes} minutes`;
    } else {
      return `${seconds} seconds`;
    }
  };

  return (
    <PubPackage>
      {props.user.quest_status === "idle" ? (
        <>
          <PubBackground
            pic="/assets/background/backgroundPub.png"
            onClick={() => {
              dialog.visible &&
                setDialog({
                  ...dialog,
                  visible: false,
                });
            }}
          />
          {props.user.energy !== 0 && (
            <PubQuestMan
              onClick={() => {
                console.log("dasdada");
                const maleZyva = new Audio("/assets/audio/maleZyva.mp3");
                maleZyva.volume = 0.05;
                maleZyva.play();
                setDialog({
                  ...dialog,
                  visible: !dialog.visible,
                });
              }}
            >
              <BlurEnding />
            </PubQuestMan>
          )}

          <PubEnergyBar>
            <PubEnergyBarFill inputWidth={`${props.user.energy}%`} />
            <PubEnergyLabel>{`energy: ${props.user.energy % 1 === 0 ? props.user.energy.toFixed(0) : props.user.energy.toFixed(2)}`}</PubEnergyLabel>
          </PubEnergyBar>

          {dialog.visible && (
            <QuestWindow>
              <QuestWindowBackground>
                {/* výběr questu */}
                <QuestTabBackground>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "flex-end",
                    }}
                  >
                    <PubTabButton
                      onClick={() => {
                        playClickSound();
                        setSelectedTab(1);
                      }}
                      selected={selectedTab === 1 && "rgba(64, 38, 29, 1)"}
                    >
                      1
                    </PubTabButton>
                    <PubTabButton
                      onClick={() => {
                        playClickSound();
                        setSelectedTab(2);
                      }}
                      selected={selectedTab === 2 && "rgba(64, 38, 29, 1)"}
                    >
                      2
                    </PubTabButton>
                    <PubTabButton
                      onClick={() => {
                        playClickSound();
                        setSelectedTab(3);
                      }}
                      selected={selectedTab === 3 && "rgba(64, 38, 29, 1)"}
                    >
                      3
                    </PubTabButton>
                  </div>
                  <div>
                    <CloseButton
                      onClick={() => {
                        playClickSound();
                        dialog.visible &&
                          setDialog({
                            ...dialog,
                            visible: false,
                          });
                      }}
                    >
                      <FontAwesomeIcon icon={faSquareXmark} size="2xl" />
                    </CloseButton>
                  </div>
                </QuestTabBackground>
                {/* message part */}
                <MessageContainer>
                  <MessageTitle>
                    {selectedTab === 1 && props.randomizeQuests[0].title}
                    {selectedTab === 2 && props.randomizeQuests[1].title}
                    {selectedTab === 3 && props.randomizeQuests[2].title}
                  </MessageTitle>
                  <MessageMessage>
                    {selectedTab === 1 && props.randomizeQuests[0].message}
                    {selectedTab === 2 && props.randomizeQuests[1].message}
                    {selectedTab === 3 && props.randomizeQuests[2].message}
                  </MessageMessage>
                  <MessageRewardTitle>Reward: </MessageRewardTitle>
                  <MessageXp>
                    {selectedTab === 1 && props.randomizeQuests[0].xp + "xp"}
                    {selectedTab === 2 && props.randomizeQuests[1].xp + "xp"}
                    {selectedTab === 3 && props.randomizeQuests[2].xp + "xp"}
                  </MessageXp>
                  <MessageMoney>
                    {selectedTab === 1 && (
                      <>
                        <Eurodollar>€$ </Eurodollar> {props.randomizeQuests[0].money}
                      </>
                    )}
                    {selectedTab === 2 && (
                      <>
                        <Eurodollar>€$ </Eurodollar> {props.randomizeQuests[1].money}
                      </>
                    )}
                    {selectedTab === 3 && (
                      <>
                        <Eurodollar>€$ </Eurodollar> {props.randomizeQuests[2].money}
                      </>
                    )}
                  </MessageMoney>
                  <MessageTime>
                    {selectedTab === 1 && "time: " + taskTime(props.randomizeQuests[0].time)}
                    {selectedTab === 2 && "time: " + taskTime(props.randomizeQuests[1].time)}
                    {selectedTab === 3 && "time: " + taskTime(props.randomizeQuests[2].time)}
                  </MessageTime>
                  <MessageBottom>
                    <MessageSelectButton
                      onClick={() => {
                        playClickSound();
                        props.selectQuest([{ ind: "" }, { quest: props.randomizeQuests[0] }, { quest: props.randomizeQuests[1] }, { quest: props.randomizeQuests[2] }], selectedTab);
                        setDialog({
                          ...dialog,
                          visible: false,
                        });
                      }}
                    >
                      Select
                    </MessageSelectButton>
                  </MessageBottom>
                </MessageContainer>
              </QuestWindowBackground>
            </QuestWindow>
          )}
        </>
      ) : props.user.quest_status === "questing" ? (
        // RUNNING QUEST PAGE
        <>
          <PubBackground
            pic={props.quest.backgroundUrl}
            onClick={() => {
              dialog.visible &&
                setDialog({
                  ...dialog,
                  visible: false,
                });
            }}
          />
          <PubNameOfQuest>{props.quest.title}</PubNameOfQuest>
          {!props.loadingButtonDisabled && (
            <PubCancelRunningQuest
              onClick={() => {
                playClickSound();
                props.setLoading(true, "all");
                props.onCancelRunningQuest();
              }}
            >
              Cancel
            </PubCancelRunningQuest>
          )}

          <PubLoadingBar>
            <PubLoadingBarFill inputWidth={loadingFill} />
          </PubLoadingBar>
          {props.user?.caps > 0 && !props.loadingButtonDisabled && (
            <PubSkipRunningQuest
              onClick={() => {
                playClickSound();
                props.setLoading(true, "all");
                props.onSkipRunningQuest();
              }}
            >
              Skip (
              <img loading="lazy" width={20} height={"auto"} src="/assets/capImage.png" alt="cap" />)
            </PubSkipRunningQuest>
          )}

          <PubTimeBar>{questLeftTime === 1110000112312313 ? "" : questLeftTime}</PubTimeBar>
        </>
      ) : (
        props.user.quest_status === "fighting" && (
          // FIGHT SCENE PAGE
          <>
            <PubBackground
              pic={props.quest.backgroundUrl}
              onClick={() => {
                dialog.visible &&
                  setDialog({
                    ...dialog,
                    visible: false,
                  });
              }}
            />
            {/* FightContainer */}
            <FightContainer>
              <FightPlayerSide>
                <FightFramePlayer shaking={shakingPlayer} margin={`${fightPlayerMargin}%`}>
                  <FightFrameContainer>
                    <FightPicture image={userPic} />
                    <FightHpFrame widthPercent={userHpPercent}>
                      <FightHpFill widthPercent={userHpPercent}></FightHpFill>
                    </FightHpFrame>
                    <FightNameLabel>{props.user.username_visible}</FightNameLabel>
                    <FightFrameLine />
                    <FightHpLabel>{`hp: ${fight.userHp}/${fight.userHpMax}`}</FightHpLabel>
                    <FightStatTable>
                      <tbody>
                        <tr>
                          <FightStatColumn>strength</FightStatColumn>
                          <FightStatColumn>{props.user.statistics.strength}</FightStatColumn>
                        </tr>
                        <tr>
                          <FightStatColumn>stamina</FightStatColumn>
                          <FightStatColumn>{props.user.statistics.stamina}</FightStatColumn>
                        </tr>
                        <tr>
                          <FightStatColumn>intelligence</FightStatColumn>
                          <FightStatColumn>{props.user.statistics.intelligence}</FightStatColumn>
                        </tr>
                        <tr>
                          <FightStatColumn>dexterity</FightStatColumn>
                          <FightStatColumn>{props.user.statistics.dexterity}</FightStatColumn>
                        </tr>
                        <tr>
                          <FightStatColumn>lucky</FightStatColumn>
                          <FightStatColumn>{props.user.statistics.lucky}</FightStatColumn>
                        </tr>
                      </tbody>
                    </FightStatTable>
                  </FightFrameContainer>
                </FightFramePlayer>
                {fight.enemyAttack !== 0 && <FightHpDeficitUser critical={fight.critical ? "red" : null} criticalSize={fight.critical ? "700%" : null}>{`-${fight.enemyAttack}`}</FightHpDeficitUser>}
              </FightPlayerSide>
              <FightEnemySide>
                <FightFrameEnemy shaking={shakingEnemy} margin={`${fightEnemyMargin}%`}>
                  <FightFrameContainer>
                    {/* change the photo to assets */}
                    <FightPicture image={props.user.quest_fight.enemy.enemyUrl !== "" ? props.user.quest_fight.enemy.enemyUrl : "https://firebasestorage.googleapis.com/v0/b/apocalypse-of-nomads.appspot.com/o/enemies%2FquestEnemy1.png?alt=media&token=1b252e28-8381-4ddb-81f5-a345462f7def"} />
                    <FightHpFrame widthPercent={enemyHpPercent}>
                      <FightHpFill widthPercent={enemyHpPercent}></FightHpFill>
                    </FightHpFrame>
                    <FightNameLabel>{props.user.quest_fight.enemy.name !== "" ? props.user.quest_fight.enemy.name : "nobody"}</FightNameLabel>
                    <FightFrameLine />
                    <FightHpLabel>{`hp: ${fight.enemyHp}/${fight.enemyHpMax}`}</FightHpLabel>
                    <FightStatTable>
                      <tbody>
                        <tr>
                          <FightStatColumn>strength</FightStatColumn>
                          <FightStatColumn>{props.user?.quest_fight.enemy?.statistics?.strength}</FightStatColumn>
                        </tr>
                        <tr>
                          <FightStatColumn>stamina</FightStatColumn>
                          <FightStatColumn>{props.user?.quest_fight.enemy?.statistics?.stamina}</FightStatColumn>
                        </tr>
                        <tr>
                          <FightStatColumn>intelligence</FightStatColumn>
                          <FightStatColumn>{props.user?.quest_fight.enemy?.statistics?.intelligence}</FightStatColumn>
                        </tr>
                        <tr>
                          <FightStatColumn>dexterity</FightStatColumn>
                          <FightStatColumn>{props.user?.quest_fight.enemy?.statistics?.dexterity}</FightStatColumn>
                        </tr>
                        <tr>
                          <FightStatColumn>lucky</FightStatColumn>
                          <FightStatColumn>{props.user?.quest_fight.enemy?.statistics?.lucky}</FightStatColumn>
                        </tr>
                      </tbody>
                    </FightStatTable>
                  </FightFrameContainer>
                </FightFrameEnemy>
                {fight.userAttack !== 0 && <FightHpDeficitEnemy critical={fight.critical ? "red" : null} criticalSize={fight.critical ? "700%" : null}>{`-${fight.userAttack}`}</FightHpDeficitEnemy>}
              </FightEnemySide>
              <FightVsText>
                <img loading="lazy" style={{ userSelect: "none", userDrag: "none" }} draggable={false} width={"50%"} height={"50%"} src="/assets/VSLabel.png" alt="adas" />
              </FightVsText>
            </FightContainer>
          </>
        )
      )}
    </PubPackage>
  );
};

export default PubScreen;
