import { GET_FETCH, RESET_FAIL, SET_FIGHTLOG, SET_STATS_LOADING, SET_LEADERBOARD, SET_LEADERBOARD_BUTTON_DOWN, SET_LEADERBOARD_SELECTED_USER, CLEAN_LEADERBOARD_SELECTED_USER, SET_QUEST_CANCELED, SET_QUEST_ALLOWED, SET_QUEST_SKIPPED, SET_QUEST_UNSKIPPED, SET_LOADINGBUTTON_DISABLED, SET_LOADINGBUTTON_ALLOWED, SET_LOADING, SET_FIGHTLOG_ARENA } from "../game.types";

const initialState = {
  user: { stat: "loading" },
  quest: {
    title: "",
    message: "",
    quest_from: "",
    quest_to: "",
    backgroundUrl: "",
    reward: "",
    xp: "",
    money: "",
  },
  questIsCanceled: false,
  questIsSkipped: false,
  loadingButtonDisabled: false,
  leaderboardList: [],
  leaderboardButtonDown: true,
  fight: { fightLog: "", whoWin: "" },
  enemyInfo: { id: "", enemyUrl: "", name: "" },
  incorrect: false,
  statsButtonLoading: false,
  errorMessage: "",
  leaderboardSelectedUser: {},
  arena_fight: {},
  arena_enemy: {},
  arena_user: {},
  arena_reward: 0,
  loading: { status: false, where: "" },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: { status: action.payload.status, where: action.payload.where },
      };
    case CLEAN_LEADERBOARD_SELECTED_USER:
      return {
        ...state,
        incorrect: false,
        errorMessage: "",
        leaderboardSelectedUser: {},
      };
    case SET_LEADERBOARD_SELECTED_USER:
      if (action.payload.error === true) {
        const errorMessage = action.payload.message.replace(").", "");
        const realErrorMessage = errorMessage.replace("Firebase: Error (auth/", "");
        return {
          ...state,
          user: { stat: "loading" },
          incorrect: true,
          errorMessage: realErrorMessage,
        };
      } else {
        return {
          ...state,
          incorrect: false,
          errorMessage: "",
          leaderboardSelectedUser: action.payload.selectedUser,
        };
      }
    case SET_LOADINGBUTTON_ALLOWED:
      console.log("SET_LOADINGBUTTON_ALLOWED");
      return {
        ...state,
        loadingButtonDisabled: false,
      };
    case SET_LOADINGBUTTON_DISABLED:
      console.log("SET_LOADINGBUTTON_DISABLED");
      return {
        ...state,
        loadingButtonDisabled: true,
      };
    case SET_QUEST_SKIPPED:
      console.log("SET_QUEST_SKIPPED");
      const editQuest = { ...state.quest, quest_to: 1653761777, skipped: true };
      return {
        ...state,
        quest: editQuest,
        questIsSkipped: true,
      };
    case SET_QUEST_UNSKIPPED:
      console.log("SET_QUEST_UNSKIPPED");
      return {
        ...state,
        questIsSkipped: false,
      };
    case SET_QUEST_CANCELED:
      console.log("SET_QUEST_CANCELED");
      return {
        ...state,
        questIsCanceled: true,
        quest: {},
      };
    case SET_QUEST_ALLOWED:
      console.log("SET_QUEST_ALLOWED");
      return {
        ...state,
        questIsCanceled: false,
      };
    case SET_LEADERBOARD_BUTTON_DOWN:
      if (action.payload === "off") {
        return {
          ...state,
          leaderboardButtonDown: false,
        };
      } else {
        return {
          ...state,
          leaderboardButtonDown: true,
        };
      }
    case SET_LEADERBOARD:
      if (action.payload.error === true) {
        console.log("[error] ", action.payload.message);
        const errorMessage = action.payload.message.replace(").", "");
        const realErrorMessage = errorMessage.replace("Firebase: Error (auth/", "");
        return {
          ...state,
          user: { stat: "loading" },
          incorrect: true,
          errorMessage: realErrorMessage,
        };
      } else {
        return {
          ...state,
          incorrect: false,
          errorMessage: "",
          leaderboardList: action.payload,
        };
      }
    case SET_FIGHTLOG_ARENA:
      if (action.payload.error === true) {
        console.log("[error] ", action.payload.message);
        const errorMessage = action.payload.message.replace(").", "");
        const realErrorMessage = errorMessage.replace("Firebase: Error (auth/", "");
        return {
          ...state,
          user: { stat: "loading" },
          incorrect: true,
          errorMessage: realErrorMessage,
        };
      } else {
        return {
          ...state,
          incorrect: false,
          errorMessage: "",
          arena_fight: {
            fightLog: action.payload.fightLog,
            youGet: action.payload.youGet,
            whoWin: action.payload.whoWin,
          },
          arena_user: action.payload.arena_user,
          arena_enemy: action.payload.arena_enemy,
          arena_reward: action.payload.arena_reward,
        };
      }
    case SET_FIGHTLOG:
      if (action.payload.error === true) {
        console.log("[error] ", action.payload.message);
        const errorMessage = action.payload.message.replace(").", "");
        const realErrorMessage = errorMessage.replace("Firebase: Error (auth/", "");
        return {
          ...state,
          user: { stat: "loading" },
          incorrect: true,
          errorMessage: realErrorMessage,
        };
      } else {
        console.log("[payload] ", action.payload);
        return {
          ...state,
          incorrect: false,
          errorMessage: "",
          fight: {
            fightLog: action.payload.fightLog,
            whoWin: action.payload.whoWin,
          },
          enemyInfo: action.payload.enemyInfo,
        };
      }
    case SET_STATS_LOADING:
      return {
        ...state,
        statsButtonLoading: action.payload,
      };
    case GET_FETCH:
      if (action.payload.error === true) {
        console.log("[error] ", action.payload.message);
        const errorMessage = action.payload.message.replace(").", "");
        const realErrorMessage = errorMessage.replace("Firebase: Error (auth/", "");

        return {
          ...state,
          user: { stat: "loading" },
          incorrect: true,
          errorMessage: realErrorMessage,
        };
      } else {
        console.log("[payload] ", action.payload);

        if (action.payload?.continueArena === true) {
          return {
            ...state,
            user: action.payload.user,
            quest: action.payload.quest,
            incorrect: false,
            errorMessage: "",
            arena_fight: {
              fightLog: action.payload.fightLog,
              youGet: action.payload.youGet,
              whoWin: action.payload.whoWin,
            },
            arena_user: action.payload.arena_user,
            arena_enemy: action.payload.arena_enemy,
            arena_reward: action.payload.arena_reward,
          };
        } else {
          return {
            ...state,
            user: action.payload.user,
            quest: action.payload.quest,
            incorrect: false,
            errorMessage: "",
          };
        }
      }
    case RESET_FAIL:
      return {
        ...state,
        incorrect: false,
        errorMessage: "",
      };
    default:
      return state;
  }
}
