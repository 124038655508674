import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "./store/actions";
import { useEffect, useState, useRef } from "react";
import Game from "./Game";
import { GameLoading, GameLoadingText, RotatingImage } from "../../styles/StyledGame";
import { toast } from "react-toastify";

const randomizeArray = (array) => {
  const newArray = [...array];
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
};

const GameScene = (props) => {
  const audioBackmusicRef = useRef();
  const [randomizeQuests, setRandomizeQuests] = useState([]);
  const [backmusicPlaying, setBackmusicPlaying] = useState(false);
  const dispatch = useDispatch();
  const userSaved = JSON.parse(localStorage.getItem("user"));
  const user = props.user;
  const enemyInfo = props.enemyInfo;
  const leaderboardList = props.leaderboardList;
  const leaderboardButtonDown = props.leaderboardButtonDown;
  const leaderboardSelectedUser = props.leaderboardSelectedUser;
  const quest = props.quest;
  const questIsCanceled = props.questIsCanceled;
  const questIsSkipped = props.questIsSkipped;
  const apiError = props.apiError;
  const apiErrorMessage = props.apiErrorMessage;
  const loadingButtonDisabled = props.loadingButtonDisabled;

  function handleQuestFightStart() {
    dispatch(Actions.questFightStart(user.key, user.username));
  }

  function handleLogout() {
    dispatch(Actions.setLoadingButton(true));
    localStorage.removeItem("user");
    toast(`Logout successful! Goodbye`);
    setTimeout(() => {
      window.open("/", "_self");
      dispatch(Actions.setLoadingButton(false));
    }, 2000);
  }

  function selectQuest(quests, selectedQuest) {
    console.log("[quest] select quest: ", quests[selectedQuest].quest);
    dispatch(Actions.selectQuest(quests[selectedQuest].quest.id, user.key, user.username));
  }

  function onCancelRunningQuest() {
    dispatch(Actions.cancelRunningQuest(user.key, user.username));
  }

  function onSkipRunningQuest() {
    dispatch(Actions.skipRunningQuest(user.key, user.username));
  }

  function handleFinishQuest() {
    setTimeout(() => {
      dispatch(Actions.finishQuest(user.key, user.username));
    }, [1000]);
  }

  function handleSellingItem(itemVarId) {
    dispatch(Actions.handleSellingItem(user.key, user.username, itemVarId));
  }

  function goUpdateInvAndShop(interactedItem, interaction, inventory) {
    dispatch(Actions.fetchInvShop(user.key, user.username, interactedItem, interaction, inventory));
  }

  function goUpdateInvAndSlots(slots, inventory) {
    dispatch(Actions.fetchInvSlots(user.key, user.username, slots, inventory));
  }

  function handleBuyStat(stat, price) {
    console.log("[profile] what want to buy? ", stat, " for ", price);
    dispatch(Actions.buyStat(user.key, user.username, stat, price));
  }

  function getLeaderboardLocal(userScore, username) {
    dispatch(Actions.getLeaderboardLocal(userScore, username));
  }

  function onLeaderBoardUpClick(userScore, username) {
    dispatch(Actions.getLeaderboardUp(userScore, username));
  }

  function onLeaderBoardDownClick(userScore, username) {
    dispatch(Actions.getLeaderboardDown(userScore, username));
  }

  function setLeaderboardSelectedUser(username) {
    dispatch(Actions.getLeaderboardSelectedUser(username));
  }

  function handleSetLoading(status, where) {
    console.log("trying loading");
    dispatch(Actions.setLoading(status, where));
  }

  function handleCleanLeaderboardSelectedUser() {
    dispatch(Actions.cleanLeaderboardSelectedUser());
  }

  function handleAttackUser(selectedUser) {
    dispatch(Actions.attackUser(user.username, user.key, selectedUser.username));
  }

  function handleFinishArenaFight() {
    setTimeout(() => {
      dispatch(Actions.finishArenaFight(user.username, user.key));
    }, [1000]);
  }

  function handleFetch() {
    dispatch(Actions.doFetch(user.username, user.key));
  }

  //CHECKING LOADED USER
  useEffect(() => {
    console.log("[info] state user: ", user);
    if (user.stat === "loading") {
      console.log("[info] user is not loaded from API. Starting now...");
      dispatch(Actions.doFetch(userSaved.username, userSaved.key, true));
      try {
        audioBackmusicRef.current.volume = 0.03;
        audioBackmusicRef.current.play().then(() => {
          setBackmusicPlaying(true);
        });
      } catch (e) {}
    } else {
      console.log("[info] user is loaded. But I should not be here in this case. hmmm...");
    }
    // eslint-disable-next-line
  }, []);

  //CHECKING USER
  useEffect(() => {
    if (user.ban === true) {
      toast.error(`You are banned! Reason: ${user.ban_reason}`);
      setTimeout(() => {
        localStorage.removeItem("user");
        window.open("/", "_self");
      }, 1500);
    } else {
      if (user.ban === false) {
        setRandomizeQuests(randomizeArray(user.quests));
      }
    }
  }, [user]);

  //CHECKING API ERROR
  useEffect(() => {
    if (apiError === true && apiErrorMessage.includes("ban")) {
      toast.error(apiErrorMessage);
      setTimeout(() => {
        localStorage.removeItem("user");
        window.open("/", "_self");
      }, 1500);
    }
    // eslint-disable-next-line
  }, [apiError]);

  return (
    <div>
      {user.stat !== "loading" && !user.ban ? (
        <div>
          <Game
            handleLogout={handleLogout}
            user={user}
            quest={quest}
            randomizeQuests={randomizeQuests}
            enemyInfo={enemyInfo}
            loading={props.loading}
            handleSellingItem={handleSellingItem}
            loadingButtonDisabled={loadingButtonDisabled}
            backmusicPlaying={backmusicPlaying}
            handleAttackUser={handleAttackUser}
            arenaFight={props.arena_fight}
            arenaEnemy={props.arena_enemy}
            arenaUser={props.arena_user}
            onFinishArenaFight={handleFinishArenaFight}
            setBackmusicPlaying={setBackmusicPlaying}
            audioBackmusicRef={audioBackmusicRef}
            goUpdateInvAndSlots={goUpdateInvAndSlots}
            goUpdateInvAndShop={goUpdateInvAndShop}
            getLeaderboardLocal={getLeaderboardLocal}
            leaderboardList={leaderboardList}
            leaderboardButtonDown={leaderboardButtonDown}
            onLeaderBoardDownClick={onLeaderBoardDownClick}
            onLeaderBoardUpClick={onLeaderBoardUpClick}
            leaderboardSelectedUser={leaderboardSelectedUser}
            handleCleanLeaderboardSelectedUser={handleCleanLeaderboardSelectedUser}
            handleFetch={handleFetch}
            setLeaderboardSelectedUser={setLeaderboardSelectedUser}
            selectQuest={selectQuest}
            questIsCanceled={questIsCanceled}
            questIsSkipped={questIsSkipped}
            onCancelRunningQuest={onCancelRunningQuest}
            onSkipRunningQuest={onSkipRunningQuest}
            questFightStart={handleQuestFightStart}
            handleFinishQuest={handleFinishQuest}
            handleBuyStat={handleBuyStat}
            statsButtonLoading={props.statsButtonLoading}
            setLoading={handleSetLoading}
          />
        </div>
      ) : (
        <GameLoading>
          <GameLoading>
            <GameLoadingText>
              <RotatingImage src="/assets/Cir.png" width={200} height={"auto"} alt="asdad" />
              <img loading="lazy" style={{ position: "absolute", translate: "-50% -50%" }} src="/assets/A.png" width={200} height={"auto"} alt="asdaasdad" />
            </GameLoadingText>
          </GameLoading>
        </GameLoading>
      )}
      <audio ref={audioBackmusicRef} id="songAudio" src="/assets/audio/backmusic2.mp3" loop />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.game.game.user,
    quest: state.game.game.quest,
    questIsCanceled: state.game.game.questIsCanceled,
    questIsSkipped: state.game.game.questIsSkipped,
    loadingButtonDisabled: state.game.game.loadingButtonDisabled,
    fight: state.game.game.fight,
    attackUser: state.game.game.attackUser,
    arena_fight: state.game.game.arena_fight,
    arena_enemy: state.game.game.arena_enemy,
    arena_user: state.game.game.arena_user,
    finishArenaFight: state.game.game.finishArenaFight,
    enemyInfo: state.game.game.enemyInfo,
    leaderboardList: state.game.game.leaderboardList,
    leaderboardButtonDown: state.game.game.leaderboardButtonDown,
    leaderboardSelectedUser: state.game.game.leaderboardSelectedUser,
    statsButtonLoading: state.game.game.statsButtonLoading,
    apiError: state.game.game.incorrect,
    apiErrorMessage: state.game.game.errorMessage,
    loading: state.game.game.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...Actions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(GameScene);
